<template>
  <div class="container">
    <div class="blur" @click="close"></div>
    <!-- <transition name="fade"> -->
    <div class="modal">
      <Icon class="close" @click="close">close</Icon>
      <h1 class="header">Request reset</h1>

      <div class="details">
        <p class="light-text">Enter E-mail to request a password reset.</p>

        <div class="form-group">
          <span class="label"><Icon>mail_outline</Icon></span>
          <span class="approve" v-if="validemail"><Icon>check</Icon></span>
          <input
            required
            class="form-field"
            type="email"
            name=""
            id="email"
            v-model="email"
            placeholder="Email"
          />
        </div>

        <p class="light-text small-text">What's the value in image below?</p>
        <img
          style="max-width: 40%; margin: 0 auto"
          :src="'data:image/jpg;base64, ' + captcha"
        />
        <br />
        <input
          style="max-width: 30%; margin: 10px auto; text-align: center"
          type="text"
          v-model="captchavalue"
          placeholder="Value"
        />
        <Button
          bgColor="var(--color-dark-green)"
          txtColor="#ffffff"
          shadow="var(--shadow-green)"
          @click="reset"
          >Reset Password</Button
        >
      </div>
    </div>
    <!-- </div> -->
    <!-- </transition> -->
  </div>
</template>
<script >
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import Icon from "./Icon.vue"
import Button from "./Button.vue"
import axios from "axios"
import { useGtag } from "vue-gtag-next"

const apiurl = "https://api.otonomi.no"
// const apiurl = "http://localhost:8085"

export default defineComponent({
  components: { Icon, Button },
  props: {},

  setup(props, { emit }) {
    const { event } = useGtag()

    const close = (event) => {
      emit("close")
    }
    const email = ref("")

    const validemail = computed(() => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email.value).toLowerCase())
    })

    const captcha = ref("")
    let captchatoken = ""
    const captchavalue = ref("")

    const url = "https://auth.otonomi.no"
    // const url = "http://localhost:8090"
    const getcaptcha = () => {
      axios({
        baseURL: url,
        url: "/captcha",
        method: "get",

        responseType: "arraybuffer",
      })
        .then((res) => {
          captchatoken = res.headers.authorization
          captcha.value = Buffer.from(res.data, "binary").toString("base64")
        })
        .catch((err) => {
          console.log(err)
        })
    }

    onMounted(() => {
      getcaptcha()
    })

    const reset = () => {
      if (validemail.value && captchavalue.value) {
        console.log(email.value, captchatoken, captchavalue.value)
        axios({
          baseURL: url,
          url: "/reqreset",
          data: {
            email: email.value,
            token: captchatoken,
            value: captchavalue.value.toString(),
          },
          method: "POST",
          headers: {
            Authorization: "Bearer " + captchatoken,
          },
        })
          .then((resp) => {
            console.log(resp.status)
            setTimeout(() => {
              router.push("/")
            }, 5000)
          })
          .catch((err) => {
            console.log(err.response.data)
          })
      }
    }

    return {
      close,
      email,

      reset,
      validemail,
      captcha,
      captchavalue,
    }
  },
})
</script>
<style lang="scss" scoped>
@import "../css/home.scss";

.material-icons {
  display: flex;
}
.bottom-spacing {
  margin-bottom: 50px;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password {
  height: 0px;
  opacity: 0;
  display: none;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter-from .modal {
  opacity: 0;
}
.fade-enter-to .modal {
  opacity: 1;
}
.fade-enter-active .modal {
  transition: opacity 500ms 500ms;
}
.fade-enter-from .blur {
  opacity: 0;
  backdrop-filter: blur(0px);
}
.fade-enter-to .blur {
  opacity: 1;
  backdrop-filter: blur(6px);
}
.fade-enter-active .blur {
  transition: backdrop-filter 500ms;
}
</style>
