<template>
  <button
    :style="{
      background: bgColor,
      color: txtColor,
      'box-shadow': shadow,
    }"
  >
    <slot></slot>
  </button>
</template>

<script>
import { ref, defineComponent } from "vue"

export default defineComponent({
  name: "Button",
  props: {
    bgColor: { type: String, default: "#888888" },
    txtColor: { type: String, default: "#ffffff" },
    shadow: { type: String, default: "var(--shadow-light)" },
  },
  setup() {},
})
</script>



<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: var(--color-bg);
  padding: 10px 22px;
  // margin: 0 1rem;
  min-width: 100px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 999px;
  border-width: 0px;
  text-align: center;
  transition: transform 0.2s;
  box-shadow: var(--shadow-dark);
  font-weight: 700;
  line-height: 2rem;
  white-space: nowrap;

  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.95);
  }
}

.downloadBt {
  --icon: #fff;
  --icon-success: #16bf78;
  display: flex;
  outline: none;
  background-color: var(--color-bg);
  border: 1px solid var(--color-light-blue-grey);
  padding: 0 22px 0 16px;
  margin-right: 35px;
  min-width: 147px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 6px;
  transition: transform 0.2s;
  &:active {
    transform: scale(0.95);
  }
  ul {
    margin: 0;
    padding: 9px 0;
    list-style: none;
    position: relative;
    backface-visibility: hidden;
    line-height: 25px;
  }
  .icon {
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 12px 10px 0 0;
    transform: translateY(calc(var(--y, 0) * 1px));
    svg {
      width: var(--w, 14px);
      height: var(--h, 15px);
      display: block;
      fill: none;
      stroke: black;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
    & > svg,
    div {
      left: var(--l, 7px);
      top: var(--t, 2px);
      position: absolute;
    }
    & > svg {
      transform: translateY(calc(var(--y, 0) * 1px));
      polyline,
      line {
        stroke-dasharray: var(--a, 12px);
        stroke-dashoffset: var(--o, 0);
        stroke: var(--s, var(--icon));
        transition: stroke-dashoffset var(--d, 0.15s), stroke 0.4s;
      }
      polyline {
        --d: 0.25s;
        --a: 17px;
        --o: 5.5px;
      }
    }
    div {
      --w: 24px;
      --h: 24px;
      --l: 0;
      --t: 8px;
      --sw: 1.5;
    }
  }
}
</style>
