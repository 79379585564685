<template>
  <div class="ThreeSun" ref="component">
    <div class="viewer" ref="threejs"></div>
  </div>
</template>

<script>
import * as THREE from "three"
import Sun from "../assets/ThreeSun/Sun"

let camera = undefined
let renderer = undefined
const rendersize = new THREE.Vector2()
const scene = new THREE.Scene()
let sun = undefined

export default {
  name: "ThreeSun",
  data() {
    return {}
  },
  mounted() {
    camera = new THREE.PerspectiveCamera(45, 1, 0.1, 10000)
    camera.position.set(0, 0, 3)

    renderer = new THREE.WebGLRenderer({
      alpha: true,
      localClippingEnabled: false,
      globalClippingEnabled: false,
    })
    // renderer.setClearColor("grey")
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.outputEncoding = THREE.sRGBEncoding
    this.$refs.threejs.appendChild(renderer.domElement)

    sun = new Sun(renderer)
    scene.add(camera, sun.group)
    // console.log(camera)
    this.animate()
  },
  methods: {
    animate() {
      sun.update()

      if (
        rendersize.width != this.$refs.threejs.clientWidth ||
        rendersize.height != this.$refs.threejs.clientHeight
      ) {
        renderer.setSize(
          this.$refs.threejs.clientWidth,
          this.$refs.threejs.clientHeight
        )
        camera.aspect =
          this.$refs.threejs.clientWidth / this.$refs.threejs.clientHeight
        camera.updateProjectionMatrix()
      }

      renderer.render(scene, camera)

      window.requestAnimationFrame(this.animate)
    },
  },
}
</script>

<style scoped lang="scss">
Canvas {
  width: 100%;
  height: 100%;
}
.ThreeSun {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  //   position: relative;

  // .background{
  //   // position: absolute;
  //   // top: 0;
  //   // left: 0;
  //   height: 100%;
  //   width: 100%;
  //   // background-color: red;
  //   // z-index: -1;
  // }
  .viewer {
    height: 100%;
    width: 100%;
    // grid-column:  1/4;
    // grid-area: viewer;
    // overflow: hidden;
  }
  .graphics {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  //   .container{
  //   // background-color: red;
  //   height: 100%;
  //   width: 100%;
  //   display: grid;
  //   // grid-template-columns: 1fr 2fr;
  //   grid-template-rows: 1fr 2fr;
  //   color: #ffffff;
  //   // z-index: 2;
}
</style>