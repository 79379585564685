
import {
  ref,
  defineComponent,
  watch,
  toRefs,
  computed,
  onMounted,
  getCurrentInstance,
} from "vue"
import { useStore } from "vuex"
import { usePerms } from "../logic/usePerms"
import Button from "./Button.vue"
import Icon from "../components/Icon.vue"
import Subscribe from "./Register.vue"
import Login from "./Login.vue"
import Register from "./Register.vue"

export default defineComponent({
  components: {
    Button,
    Icon,
    Subscribe,
    Login,
    Register,
  },
  // msg!: string

  setup() {
    return {}
  },
})
