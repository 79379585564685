import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag-next";
// @ts-ignore
import { i18n } from 'vue-lang-router'
// @ts-ignore
import VueSafeHTML from 'vue-safe-html';

createApp(App).use(store).use(router).use(i18n).use(VueSafeHTML).use(VueGtag, {
    property: {
        id: "G-0M2YSL1KT7"
    }
}).mount('#app')
// @ts-ignore



