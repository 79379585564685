<template>
  <div class="container">
    <div class="blur" @click="close"></div>

    <div class="modal modal-login">
      <Icon class="close" @click="close">close</Icon>
      <h1 class="header">Log In</h1>

      <div class="details">
        <p class="light-text">Log in with your credentials.</p>

        <form id="app" @submit.prevent="login" method="post">
          <div class="form-group">
            <span class="label"><Icon>mail_outline</Icon></span>
            <span class="approve" v-if="validemail"><Icon>check</Icon></span>
            <input
              class="form-field"
              id="email"
              type="email"
              v-model="email"
              placeholder="Email"
              :class="{ error: errors.email }"
            />
          </div>
          <div v-if="errors.email" class="form-error-field">
            {{ errors.email }}
          </div>
          <div class="form-group" v-if="reqName">
            <span class="label"><Icon>lock_open</Icon></span>
            <input
              class="form-field"
              type="text"
              v-model="name"
              placeholder="name"
              :class="{ error: errors.password }"
            />
          </div>

          <div class="form-group" v-if="reqPasscode">
            <span class="label"><Icon>lock_open</Icon></span>
            <input
              class="form-field"
              type="text"
              v-model="passcode"
              placeholder="passcode"
              :class="{ error: errors.password }"
            />
          </div>
          <p v-if="reqPasscode" class="notice pointer">
            Check e-mail for passcode
          </p>
          <div v-if="errors.password" class="form-error-field">
            {{ errors.password }}
          </div>

          <!-- <p class="notice pointer">
            <u @click="store.commit('setModal', 3)">Forgot password?</u>
          </p> -->
        </form>
        <Button
          bgColor="var(--color-bg-dark)"
          txtColor="#ffffff"
          shadow="var(--shadow-dark)"
          v-on:click="login()"
          >LOG IN</Button
        >
        <!-- <Button
          class="btn-secondary"
          bgColor="#ffffff"
          txtColor="var(--color-text)"
          shadow="var(--shadow-light)"
          @click="store.commit('setModal', 1)"
          >BECOME MEMBER</Button
        > -->
      </div>
    </div>
    <!-- </transition> -->
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import Icon from "./Icon.vue"
import Button from "./Button.vue"
import axios from "axios"
import { useGtag } from "vue-gtag-next"

const apiurl = "https://api.otonomi.no"
// const apiurl = "http://localhost:8085"

export default defineComponent({
  components: { Icon, Button },
  props: {},

  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const { event } = useGtag()

    const errors = ref({ email: undefined, password: undefined })
    const host = computed(() => store.getters["auth/host"])

    const close = (e) => {
      emit("close")
    }
    console.log(route.path)

    const email = ref("")
    const name = ref("")
    const reqName = ref(false)
    const passcode = ref("")
    const reqPasscode = ref(false)

    const login = () => {
      let data = {}

      if (!validemail.value) {
        errors.value.email = "Invalid email"
      } else {
        errors.value.email = null
      }
      data = { ...data, email: email.value }

      if (name.value != "") data = { ...data, name: name.value }
      if (passcode.value != "") data = { ...data, passcode: passcode.value }

      axios({
        baseURL: host.value,
        url: "/login",
        data,
        method: "POST",
      })
        .then((resp) => {
          console.log(resp.data)
          let { message, reqname, reqpasscode, refresh, token } = resp.data
          if (refresh && token) {
            store.dispatch("auth/login", { refresh, token })
            emit("close")
            return
          }
          reqName.value = reqname ? true : false
          reqPasscode.value = reqpasscode ? true : false
          errors.value.message = message || ""
        })
        .catch((err) => {})

      // if (!password.value) {
      //   errors.value.password = "Password required"
      // } else if (password.value.length < 8) {
      //   errors.value.password = "Password needs to be longer than 8 characters"
      // } else {
      //   errors.value.password = null
      // }
      // if (validemail.value && password.value.length >= 8) {
      //   store.dispatch("auth/login", {
      //     email: email.value,
      //     password: password.value,
      //   })
      //   if (!store.getters["auth/isLogedIn"]) {
      //     errors.value.password = "Invalid email or password, please try again"
      //   }
      // }
    }

    const validemail = computed(() => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email.value).toLowerCase())
    })

    return {
      islogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      login,
      close,
      email,
      name,
      reqName,
      passcode,
      reqPasscode,
      store,
      errors,
      validemail,
    }
  },
})
</script>
<style lang="scss" scoped>
@import "../css/home.scss";

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice {
  font-size: 0.9rem;
  text-align: right;
}
.password {
  height: 0px;
  opacity: 0;
  display: none;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter-from .subscribe {
  opacity: 0;
}
.fade-enter-to .subscribe {
  opacity: 1;
}
.fade-enter-active .subscribe {
  transition: opacity 500ms 500ms;
}
.fade-enter-from .blur {
  opacity: 0;
  backdrop-filter: blur(0px);
}
.fade-enter-to .blur {
  opacity: 1;
  backdrop-filter: blur(6px);
}
.fade-enter-active .blur {
  transition: backdrop-filter 500ms;
}

.remodal-content-wrapper {
  background-color: #faf9f8;
  box-sizing: border-box;
  padding: 64px 24px 32px;
  max-width: 500px;

  .remodal-close {
    height: 22px;
    overflow: hidden;
    position: absolute;
    right: 21px;
    text-indent: -9999px;
    top: 21px;
    width: 22px;

    &::before {
      background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3EFoundation%20%2F%20Icon%20%2F%2024%20%2F%20Control%20%2F%20Close%3C%2Ftitle%3E%3Cdefs%3E%3Cpath%20d%3D%22M12%2011.29L21.29%202l.71.71L12.71%2012%2022%2021.29l-.71.71L12%2012.71%202.71%2022%202%2021.29%2011.29%2012%202%202.71%202.71%202%2012%2011.29z%22%20id%3D%22prefix__a%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%2F%3E%3Cuse%20fill%3D%22%23222%22%20xlink%3Ahref%3D%22%23prefix__a%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .remodal-content,
  .remodal-footer {
    background-color: #faf9f8;
    text-align: center;
  }
  .remodal-content {
    .header {
      text-align: center;
      margin-bottom: 48px;

      .heading {
        font-size: 20px;
        line-height: 24px;
        background-position: top center;
        background-size: 90px;
        margin: 0 0 12px;
        text-align: center;
      }
      .excerpt {
        margin: 0 0 20px;
      }
    }
    form {
      text-align: left;

      .inputwrapper {
        margin: 13px 0;
        max-width: 100%;
      }
    }
  }
}
</style>
