
import NavBar from "./components/Navbar.vue"
import Langselect from "./components/Langselect.vue"

import { onMounted, defineComponent } from "vue"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    NavBar,
    Langselect,
  },
  setup() {
    const store = useStore()
    const refresh = () => {
      store.dispatch("auth/refresh").catch(() => {
        return
      })
    }
    onMounted(refresh)

    return {
      refresh,
      store,
    }
  },
})
