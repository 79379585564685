<template>
  <div class="container">
    <div class="blur" @click="store.commit('setModal', 0)"></div>
    <!-- <transition name="fade"> -->
    <div class="modal">
      <Icon class="close" @click="store.commit('setModal', 0)">close</Icon>
      <h3 class="header">Select language</h3>

      <!-- <language-switcher
        v-slot="{ links }"
        active-class="router-link-exact-active"
      > -->
      <router-link
        :to="link.url"
        v-for="link in links"
        :key="link.langIndex"
        :class="link.activeClass"
        exact-active-class=""
        @click="store.commit('setModal', 0)"
      >
        {{ link.langName }}
      </router-link>
      <!-- </language-switcher> -->
    </div>
    <!-- </div> -->
    <!-- </transition> -->
  </div>
</template>
<script >
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import Icon from "./Icon.vue"
import Button from "./Button.vue"
import axios from "axios"
import { useGtag } from "vue-gtag-next"
import { useStore } from "vuex"

export default defineComponent({
  components: { Icon, Button },
  props: { links: Array },

  setup(props, { emit }) {
    const store = useStore()

    return { store }
  },
})
</script>
<style lang="scss" scoped>
@import "../css/home.scss";

.router-language-switcher {
  height: 30px;
  width: 150px;
  border: 1px #000 solid;
  .router-link-active {
    padding: 5px 10px;
    z-index: 2;
    margin: 0 auto;

    &:not(.init) {
      width: 130px;
    }
    &:not(.init):hover,
    &.router-link-exact-active:not(.init) {
      color: var(--color-blue);
      font-weight: 800;
    }
    .init {
      cursor: pointer;
    }
  }
}

.material-icons {
  display: flex;
}
.bottom-spacing {
  margin-bottom: 50px;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter-from .modal {
  opacity: 0;
}
.fade-enter-to .modal {
  opacity: 1;
}
.fade-enter-active .modal {
  transition: opacity 500ms 500ms;
}
.fade-enter-from .blur {
  opacity: 0;
  backdrop-filter: blur(0px);
}
.fade-enter-to .blur {
  opacity: 1;
  backdrop-filter: blur(6px);
}
.fade-enter-active .blur {
  transition: backdrop-filter 500ms;
}
</style>
