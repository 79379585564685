<template>
  <span class="material-icons"><slot></slot></span>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  setup() {},
})
</script>

<style scoped>
</style>
