<template>
  <footer class="footer">
    <div class="inner">
      <div class="column is-logo">
        <router-link to="/" class="main-logo is-light">
          <div class="logo">OTONOMI</div>
          <div class="text">DAYLIGHT</div>
          <span class="copyright">© 2021 dataTrees. All rights reserved.</span>
        </router-link>
      </div>
      <div class="column is-navigation">
        <!-- <div class="column-title">{{ $t("footer.navigation") }}</div>
        <ul>
          <li>
            <router-link to="/">{{ $t("footer.home") }}</router-link>
          </li>
          <li>
            <router-link to="/">Pricing</router-link>
          </li>
        </ul> -->
      </div>
      <div class="column is-navigation">
        <div class="column-title">{{ $t("footer.contact") }}</div>
        <ul>
          <li>
            <a href="mailto:info@otonomi.no" target="_blank"
              ><span class="icon email"
                ><svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.667 2.3335H2.33366C1.69199 2.3335 1.17283 2.8585 1.17283 3.50016L1.16699 10.5002C1.16699 11.1418 1.69199 11.6668 2.33366 11.6668H11.667C12.3087 11.6668 12.8337 11.1418 12.8337 10.5002V3.50016C12.8337 2.8585 12.3087 2.3335 11.667 2.3335ZM11.667 4.66683L7.00033 7.5835L2.33366 4.66683V3.50016L7.00033 6.41683L11.667 3.50016V4.66683Z"
                    fill="#8B8B8B"
                  ></path>
                </svg> </span
              >info@otonomi.no</a
            >
          </li>
          <li>
            <a href="https://twitter.com/TreesData" target="_blank"
              ><span class="icon twitter"
                ><svg
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.954 2.323C23.069 2.712 22.124 2.977 21.129 3.098C22.143 2.487 22.923 1.524 23.292 0.375C22.341 0.93 21.287 1.334 20.165 1.559C19.269 0.6 17.992 0 16.574 0C13.857 0 11.654 2.203 11.654 4.917C11.654 5.307 11.699 5.682 11.781 6.041C7.691 5.848 4.066 3.884 1.64 0.915C1.213 1.637 0.974 2.476 0.974 3.39C0.974 5.1 1.844 6.603 3.162 7.486C2.355 7.46 1.596 7.238 0.934 6.87V6.931C0.934 9.316 2.627 11.305 4.88 11.758C4.467 11.869 4.031 11.929 3.584 11.929C3.27 11.929 2.969 11.899 2.668 11.843C3.299 13.796 5.113 15.22 7.272 15.26C5.592 16.579 3.463 17.365 1.17 17.365C0.78 17.365 0.391 17.342 0 17.298C2.189 18.692 4.768 19.507 7.557 19.507C16.611 19.507 21.556 12.011 21.556 5.521C21.556 5.312 21.556 5.101 21.541 4.891C22.502 4.202 23.341 3.331 24.001 2.343L23.954 2.323Z"
                    fill="#1DA1F2"
                  ></path>
                </svg> </span
              >TreesData</a
            >
          </li>

          <li>
            <a
              href="https://www.linkedin.com/company/data-trees"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="icon discord"
                ><svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.2991 21.3043H17.597V15.5032C17.597 14.1199 17.5689 12.3397 15.6678 12.3397C13.7376 12.3397 13.4428 13.8449 13.4428 15.4011V21.3043H9.74069V9.37506H13.297V11.0011H13.3449C13.8418 10.0636 15.0501 9.07402 16.8553 9.07402C20.6064 9.07402 21.3001 11.5428 21.3001 14.7564V21.3043H21.2991ZM5.55941 7.74276C4.36774 7.74276 3.41044 6.77817 3.41044 5.5917C3.41044 4.40628 4.36878 3.44273 5.55941 3.44273C6.74692 3.44273 7.70943 4.40628 7.70943 5.5917C7.70943 6.77817 6.74588 7.74276 5.55941 7.74276ZM7.41568 21.3043H3.70315V9.37506H7.41568V21.3043ZM23.1512 0H1.8448C0.825006 0 0 0.806255 0 1.80105V23.1991C0 24.195 0.825006 25.0002 1.8448 25.0002H23.1481C24.1668 25.0002 25.0002 24.195 25.0002 23.1991V1.80105C25.0002 0.806255 24.1668 0 23.1481 0H23.1512Z"
                    fill="white"
                  ></path>
                </svg> </span
              >LinkedIn</a
            >
          </li>

          <li>
            <a
              href="https://www.trees.no"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="icon discord">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                    fill="white"
                  />
                </svg> </span
              >dataTrees</a
            >
          </li>
        </ul>
      </div>
      <div class="column is-legal">
        <div class="column-title">{{ $t("footer.legal") }}</div>
        <ul>
          <li>
            <a href="#">{{ $t("footer.privacy") }}</a>
          </li>
          <li>
            <a href="#">{{ $t("footer.terms") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import {
  ref,
  defineComponent,
  watch,
  toRefs,
  computed,
  onMounted,
  getCurrentInstance,
} from "vue"
import { useStore } from "vuex"
import { usePerms } from "../logic/usePerms"
import Button from "./Button.vue"
import Icon from "../components/Icon.vue"
import Subscribe from "./Register.vue"
import Login from "./Login.vue"
import Register from "./Register.vue"

export default defineComponent({
  components: {
    Button,
    Icon,
    Subscribe,
    Login,
    Register,
  },
  // msg!: string

  setup() {
    return {}
  },
})
</script>


<style scoped lang="scss">
@import "../css/home.scss";
</style>
