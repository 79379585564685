

<!-- eslint @intlify/vue-i18n/no-v-html: 'off' -->
<template>
  <div>
    <main>
      <section class="page home">
        <!-- <section class="landing">
          <div class="content">
            <h1
              class="element main-title"
              v-safe-html="$t('landing.title')"
            ></h1>

            <div class="element description">
              <p v-safe-html="$t('landing.description')"></p>
            </div>

            <span class="element separator"></span>

            <div class="element students">
              <strong>+1.000.000 m²</strong> {{ $t("landing.analysed") }}
            </div>
            <Button
              @click="store.commit('setModal', 1)"
              bgColor="var(--color-dark-blue)"
              txtColor="#ffffff"
              shadow="var(--shadow-dark)"
            >
              {{ $t("landing.startMessage") }}</Button
            >
            <ul class="features opacity-5">
              <li class="check">CIE 171:2006</li>
              <li class="check">NS-EN 12464-1:2011</li>
              <li class="check">EN 17037:2018</li>
              <li class="check">IES LM-83-12</li>
            </ul>
            <ul class="features opacity-5">
              <li class="flag">
                <a
                  href="https://dibk.no/regelverk/byggteknisk-forskrift-tek17/13/v/13-7/"
                  ><img
                    width="15"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/512px-Flag_of_Norway.svg.png"
                /></a>
                TEK 17 § 13-7. Lys
              </li>
              <li class="flag">
                <a
                  href="https://www.boverket.se/sv/byggande/halsa-och-inomhusmiljo/ljussolljus/dagsljus/"
                  ><img
                    width="15"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Sweden.svg/512px-Flag_of_Sweden.svg.png"
                /></a>
                BBR BFS 2020:4 § 6:322 Dagsljus
              </li>
              <li class="flag">
                <a
                  href="https://bygningsreglementet.dk/Historisk/BR18_Version5/Tekniske-bestemmelser/18/Krav/379_381"
                  ><img
                    width="15"
                    src="https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg"
                /></a>
                BR18 1.2 Dagslys (§ 379-381)
              </li>
            </ul>
          </div>

          <div class="experience">
            <demoRoom />

            <router-link to="/viewer" class="click">
              <Button
                bgColor="#ffffff"
                txtColor="var(--color-text-light)"
                shadow="var(--shadow-light)"
                ><Icon>play_circle_outline</Icon>
                {{ $t("landing.exploreDemo") }}</Button
              >
            </router-link>
          </div>
        </section> -->

        <section class="landing" style="
            background: url(../assets/images/splashpage.png) no-repeat center
              center fixed;
          ">
          <img class="full-bg-img" src="../assets/images/splashpage.png" />
          <div class="content">
            <h1 class="element main-title" v-html="$t('landing.title')"></h1>

            <div class="element description">
              <p v-safe-html="$t('landing.description')"></p>
            </div>

            <!-- <span class="element separator"></span>

            <div class="element students">
              <strong>+1.000.000 m²</strong> {{ $t("landing.analysed") }}
            </div> -->
            <Button @click="
              islogedIn
                ? $router.push('/projects')
                : store.commit('setModal', 1)
            " bgColor="var(--color-text)" txtColor="#ffffff" shadow="var(--shadow-dark)">
              {{ $t("landing.startMessage") }}
              <Icon style="margin-left: 12px">east</Icon>
            </Button>
            <!-- <ul class="features opacity-5">
              <li class="check">CIE 171:2006</li>
              <li class="check">NS-EN 12464-1:2011</li>
              <li class="check">EN 17037:2018</li>
              <li class="check">IES LM-83-12</li>
            </ul> -->
            <ul class="features">
              <li class="comp_category">
                <a href="https://dibk.no/regelverk/byggteknisk-forskrift-tek17/13/v/13-7/">
                  ✔️ TEK 17
                </a>
              </li>
              <li class="comp_category">
                <a href="https://www.boverket.se/sv/byggande/halsa-och-inomhusmiljo/ljussolljus/dagsljus/">
                  ✔️ BBR BFS 2020:4</a>
              </li>
              <li class="comp_category">
                <a href="https://bygningsreglementet.dk/Historisk/BR18_Version5/Tekniske-bestemmelser/18/Krav/379_381">
                  ✔️ BR18 1.2 Dagslys
                </a>
              </li>
              <!-- <li class="comp_category">
                <a
                  href="https://bygningsreglementet.dk/Historisk/BR18_Version5/Tekniske-bestemmelser/18/Krav/379_381"
                >
                  BREEAM
                </a>
              </li>
              <li class="comp_category">
                <a
                  href="https://bygningsreglementet.dk/Historisk/BR18_Version5/Tekniske-bestemmelser/18/Krav/379_381"
                >
                  LEED
                </a>
              </li>
              <li class="comp_category">
                <a
                  href="https://bygningsreglementet.dk/Historisk/BR18_Version5/Tekniske-bestemmelser/18/Krav/379_381"
                >
                  SVANEN
                </a>
              </li> -->
            </ul>
          </div>

          <div class="experience">
            <!-- <demoRoom /> -->

            <!-- <router-link to="/viewer" class="click">
              <Button bgColor="#ffffff" txtColor="var(--color-text-light)" shadow="var(--shadow-light)">
                <Icon>play_circle_outline</Icon>
                {{ $t("landing.exploreDemo") }}
              </Button>
            </router-link> -->
            <router-link to="/webinar" class="click">
              <Button bgColor="var(--color-blue)" txtColor="var(--color-text-light)" shadow="var(--shadow-light)">
                <Icon>play_circle_outline</Icon>

                {{ $t("landing.signupWebinar") }}

              </Button>
            </router-link>
          </div>
        </section>

        <section class="partners">
          <div class="partners-container">
            <div class="partners-title">{{ $t("landing.trusted") }}</div>
            <div class="logo_container" target="_blank">
              <div class="logo-animation-container">
                <div class="logo-animation">
                  <a class="front" href="http://www.rodeo-arkitekter.no/" target="_blank">
                    <img src="../assets/images/logos/logo_rodeo.png" class="logo_partner" /></a>

                  <a class="back" href="https://www.architectopia.com/" target="_blank">
                    <img src="../assets/images/logos/logo_architectopia.png" class="logo_partner" /></a>
                </div>
              </div>
              <div class="logo-animation-container">
                <div class="logo-animation">
                  <a class="front" href="https://semren-mansson.se/" target="_blank">
                    <img src="../assets/images/logos/logo_semren_manssan.png" class="logo_partner" /></a>
                  <a class="back" href="https://byggalliansen.no/" target="_blank">
                    <img src="../assets/images/logos/logo_green_alliance_member.png" class="logo_partner" /></a>
                </div>
              </div>

              <div class="logo-animation-container">
                <div class="logo-animation">
                  <a class="front" href="https://graphisoft.no/">
                    <img src="../assets/images/logos/logo_graphisoft.png" class="logo_partner" /></a>

                  <a class="back" href="https://simenergi.no/" target="_blank">
                    <img src="../assets/images/logos/logo_simien.png" class="logo_partner" /></a>
                </div>
              </div>

              <div class="logo-animation-container">
                <div class="logo-animation">
                  <a class="front" href="https://www.buildingsmart.org/" target="_blank">
                    <img src="../assets/images/logos/logo_building_smart.png" class="logo_partner" /></a>
                  <a class="back" href="https://sgco.no/" target="_blank">
                    <img src="../assets/images/logos/logo_sg.png" class="logo_partner" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="team">
          <div class="content">
            <div class="photo">
              <img class="full-width" src="../assets/images/process.png" />
            </div>

            <h2 class="section-title" v-html="$t('onboarding.title')"></h2>

            <div class="description" v-html="$t('onboarding.description')">
            </div>
            <!-- <p>
                          Otonomi’s daylight app is a breeze to get started with.It’s
                          just a matter of:
          </p>
            < ul >
            <li>create an account < /li>
              < li > upload an IFC of your project < /li>
                < li > Send for simulation < /li>
                  < /ul>
                  <p>
                          And you’re done! Now check out our
            < strong >
            <router-link to =" /viewer" style="color: white">{{ $t("global.demo") }} project</router-link>
              </strong>
              or
              <strong><span @click="store.commit('setModal', 1)">register</span></strong>
              an account.
              </p> -->
          </div>
        </section>

        <section class="promotion" id="promotion">
          <div class="content">
            <div class="section-title" v-html="$t('promotion.title')"></div>

            <div class="description">
              <p v-safe-html="$t('promotion.description')"></p>
            </div>

            <div class="numbers">
              <div class="number">
                <div class="value">5</div>
                <div class="label">{{ $t("promotion.standards") }}</div>
              </div>
              <div class="separator"></div>
              <div class="number">
                <div class="value">8</div>
                <div class="label">{{ $t("promotion.metrics") }}</div>
              </div>
              <div class="separator"></div>
              <div class="number">
                <div class="value">24</div>
                <div class="label">{{ $t("promotion.hours") }}</div>
              </div>
            </div>
          </div>

          <object class="promotion-video" data="https://www.youtube.com/embed/U4RC0pWLDVw?rel=0" width="560"
            height="315"></object>

          <div class="content cards">
            <div class="items">
              <div class="item">
                <img src="../assets/images/room_all.png" />
                <div class="label">{{ $t("promotion.convenience") }}</div>
                <div class="description">
                  <p v-safe-html="$t('promotion.convenienceDesc')"></p>
                </div>
              </div>

              <div class="item">
                <img src="../assets/images/room_process.png" />
                <div class="label">{{ $t("promotion.fast") }}</div>
                <div class="description">
                  <p v-safe-html="$t('promotion.fastDesc')"></p>
                </div>
              </div>

              <div class="item">
                <img src="../assets/images/room_smart.png" />
                <div class="label">{{ $t("promotion.smart") }}</div>
                <div class="description">
                  <p v-safe-html="$t('promotion.smartDesc')"></p>
                </div>
              </div>

              <div class="item">
                <img src="../assets/images/room_rank.png" />
                <div class="label">{{ $t("promotion.analysis") }}</div>
                <div class="description">
                  <p v-safe-html="$t('promotion.analysisDesc')"></p>
                </div>
              </div>

              <div class="item">
                <img src="../assets/images/room_metrics.png" />
                <div class="label">{{ $t("promotion.metrics") }}</div>
                <div class="description">
                  <p v-safe-html="$t('promotion.metricsDesc')"></p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="program">
          <div class="columns">
            <div class="column">
              <div class="intro" data-index="00">
                <h2 class="section-title">{{ $t("program.title") }}</h2>
                <div class="description">
                  <p v-safe-html="$t('program.description')"></p>
                </div>
              </div>
              <div class="chapter chapter-02" data-index="02">
                <div class="illustration">
                  <img src="../assets/images/room_UDI.png" />
                </div>
                <small class="number element">{{
                    $t("program.daylight")
                }}</small>
                <h3 class="section-title element">
                  {{ $t("program.UDItitle") }}
                </h3>
                <Slider :max="1" :value="0" :isDisabled="true"
                  :gradientColors="['#3f0751', '#39AEC3', '#f3e81c', '#e63363']" />
                <div class="description element">
                  <p v-safe-html="$t('program.UDIdesc')"></p>
                </div>
                <div class="information element">
                  <span class="format-icon">
                    <Icon>arrow_drop_down</Icon>
                  </span>
                  1 metric — 4 categories
                </div>
                <ul class="lessons">
                  <li class="element">
                    <span class="lesson-number">01</span>
                    <span class="lesson-title">'Fail' in providing sufficient daylight</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">02</span>
                    <span class="lesson-title">'Supplemental' light may be desired</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">03</span>
                    <span class="lesson-title">'Acceptable' daylight is achieved</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">04</span>
                    <span class="lesson-title">'Excessive' daylight levels</span>
                  </li>
                </ul>
              </div>
              <!-- <div class="chapter chapter-04" data-index="04">
                <div class="illustration">
                  <img src="../assets/images/room_SUN.png" />
                </div>
                <small class="number element">{{
                  $t("program.sunlight")
                }}</small>
                <h3 class="section-title element">
                  {{ $t("program.DGPtitle") }}
                </h3>
                <div class="description element">
                  <p v-safe-html="$t('program.DGPdesc')"></p>
                </div>
                <div class="information element">
                  <span class="format-icon"><Icon>arrow_drop_down</Icon> </span>
                  1 metric — 3 recommendations
                </div>
                <ul class="lessons">
                  <li class="element">
                    <span class="lesson-number">01</span>
                    <span class="lesson-title">Minimum glare protection</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">02</span>
                    <span class="lesson-title">Medium glare protection</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">03</span>
                    <span class="lesson-title">High glare protection</span>
                  </li>
                </ul>
              </div> -->
            </div>

            <div class="column">
              <div class="chapter chapter-01" data-index="01">
                <div class="illustration">
                  <img src="../assets/images/room_DA.png" />
                </div>
                <small class="number element">{{
                    $t("program.daylight")
                }}</small>
                <h3 class="section-title element">
                  {{ $t("program.DAtitle") }}
                </h3>
                <Slider :max="1" :value="0" :isDisabled="true"
                  :gradientColors="['#2f0087', '#9f0e9c', '#e67853', '#e4fa13']" />
                <div class="description element">
                  <p v-safe-html="$t('program.DAdesc')"></p>
                </div>
                <div class="information element">
                  <span class="format-icon">
                    <Icon>arrow_drop_down</Icon>
                  </span>
                  3 metrics — time and space
                </div>
                <ul class="lessons">
                  <li class="element">
                    <span class="lesson-number">01</span>
                    <span class="lesson-title">Daylight Autonomy</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">02</span>
                    <span class="lesson-title">Spatial Daylight Autonomy</span>
                  </li>
                  <li class="element">
                    <span class="lesson-number">03</span>
                    <span class="lesson-title">Continuous Daylight Autonomy</span>
                  </li>
                </ul>
              </div>
              <div class="chapter chapter-03" data-index="03">
                <div class="illustration">
                  <img src="../assets/images/room_DF.png" />
                </div>
                <small class="number element">{{
                    $t("program.daylight")
                }}</small>
                <h3 class="section-title element">
                  {{ $t("program.DFtitle") }}
                </h3>
                <Slider :max="1" :value="0" :isDisabled="true" :gradientColors="[
                  '#0b2b83',
                  '#1872af',
                  '#44a9bd',
                  '#8dd2ba',
                  '#d9f0ba',
                  '#ffffd9',
                ]" />
                <div class="description element">
                  <p v-safe-html="$t('program.DFdesc')"></p>
                </div>
                <div class="information element">
                  <span class="format-icon">
                    <Icon>arrow_drop_down</Icon>
                  </span>
                  1 metric — 1 dimension
                </div>
                <ul class="lessons">
                  <li class="element">
                    <span class="lesson-number">01</span>
                    <span class="lesson-title">CIE cloudy sky</span>
                  </li>
                </ul>
              </div>
              <div class="chapter chapter-05" data-index="05">
                <div class="illustration">
                  <img src="../assets/images/room_SUN.png" />
                </div>
                <small class="number element">{{
                    $t("program.sunlight")
                }}</small>
                <h3 class="section-title element">
                  {{ $t("program.ASEtitle") }}
                </h3>
                <Slider :max="1" :value="0" :isDisabled="true" :gradientColors="['#DE2E17']" />

                <div class="description element">
                  <p v-safe-html="$t('program.ASEdesc')"></p>
                </div>
                <div class="information element">
                  <span class="format-icon">
                    <Icon>arrow_drop_down</Icon>
                  </span>
                  1 metric — time and space
                </div>
                <ul class="lessons">
                  <li class="element">
                    <span class="lesson-number">01</span>
                    <span class="lesson-title">ASE 1000 lux / 250 hours</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section class="team">
          <div class="content">
            <div class="photo">
              <span class="gradient"></span>
              <span class="objects">
                <div class="object person">
                  <div class="aside__image">
                    <img src="../assets/images/PIC_andre.jpg" />
                  </div>
                  <h3 class="aside__title">André Agi</h3>
                  <p class="aside__text h210">
                    Making design decisions is part of an architect's everyday
                    life. André targets design complexity through algorithms and
                    graphics to communicate design intent and allow for better
                    outcomes.
                  </p>
                  <a class="aside__link" href="https://www.linkedin.com/in/andreagi/" target="_blank">LinkedIn</a>
                </div>
                <div class="object person">
                  <div class="aside__image">
                    <img src="../assets/images/PIC_franz.jpg" />
                  </div>
                  <h3 class="aside__title">FRANZ FORSBERG</h3>
                  <p class="aside__text h210">
                    A successful design team includes architects and engineers,
                    at the service of society. Franz aims at bridging the gap
                    between them, focusing on sustainable environmental design
                    and circularity.
                  </p>
                  <a class="aside__link" href="https://www.linkedin.com/in/franz-forsberg-350237137/"
                    target="_blank">LinkedIn</a>
                </div>
                <div class="object person">
                  <div class="aside__image">
                    <img src="../assets/images/PIC_stian2.jpg" />
                  </div>
                  <h3 class="aside__title">STIAN HAUGRIM</h3>
                  <p class="aside__text h210">
                    Our work is dependent on technology. Stian connect
                    technology and buildings, looking at what's happening in
                    both worlds to find similarity and figuring out how to
                    connect them.
                  </p>
                  <a class="aside__link" href="https://www.linkedin.com/in/stian-andr%C3%A9-haugrim-768757113/"
                    target="_blank">LinkedIn</a>
                </div>
              </span>
            </div>

            <h2 class="section-title">
              {{ $t("team.title") }}
            </h2>

            <div class="description">
              <p v-safe-html="$t('team.description')"></p>
            </div>

            <div class="social-links">
              <a class="link website" href="https://trees.no" target="_blank"><span class="website-logo">
                  <Icon>language</Icon>
                </span></a>
              <a class="link twitter" href="https://twitter.com/TreesData" target="_blank"><span
                  class="twitter-logo"><svg width="24" height="20" viewBox="0 0 24 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M23.954 2.323C23.069 2.712 22.124 2.977 21.129 3.098C22.143 2.487 22.923 1.524 23.292 0.375C22.341 0.93 21.287 1.334 20.165 1.559C19.269 0.6 17.992 0 16.574 0C13.857 0 11.654 2.203 11.654 4.917C11.654 5.307 11.699 5.682 11.781 6.041C7.691 5.848 4.066 3.884 1.64 0.915C1.213 1.637 0.974 2.476 0.974 3.39C0.974 5.1 1.844 6.603 3.162 7.486C2.355 7.46 1.596 7.238 0.934 6.87V6.931C0.934 9.316 2.627 11.305 4.88 11.758C4.467 11.869 4.031 11.929 3.584 11.929C3.27 11.929 2.969 11.899 2.668 11.843C3.299 13.796 5.113 15.22 7.272 15.26C5.592 16.579 3.463 17.365 1.17 17.365C0.78 17.365 0.391 17.342 0 17.298C2.189 18.692 4.768 19.507 7.557 19.507C16.611 19.507 21.556 12.011 21.556 5.521C21.556 5.312 21.556 5.101 21.541 4.891C22.502 4.202 23.341 3.331 24.001 2.343L23.954 2.323Z"
                      fill="#1DA1F2"></path>
                  </svg> </span></a>
              <a class="link linkedin" href="https://www.linkedin.com/company/data-trees" target="_blank"><span
                  class="linkedin-logo"><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.2991 21.3043H17.597V15.5032C17.597 14.1199 17.5689 12.3397 15.6678 12.3397C13.7376 12.3397 13.4428 13.8449 13.4428 15.4011V21.3043H9.74069V9.37506H13.297V11.0011H13.3449C13.8418 10.0636 15.0501 9.07402 16.8553 9.07402C20.6064 9.07402 21.3001 11.5428 21.3001 14.7564V21.3043H21.2991ZM5.55941 7.74276C4.36774 7.74276 3.41044 6.77817 3.41044 5.5917C3.41044 4.40628 4.36878 3.44273 5.55941 3.44273C6.74692 3.44273 7.70943 4.40628 7.70943 5.5917C7.70943 6.77817 6.74588 7.74276 5.55941 7.74276ZM7.41568 21.3043H3.70315V9.37506H7.41568V21.3043ZM23.1512 0H1.8448C0.825006 0 0 0.806255 0 1.80105V23.1991C0 24.195 0.825006 25.0002 1.8448 25.0002H23.1481C24.1668 25.0002 25.0002 24.195 25.0002 23.1991V1.80105C25.0002 0.806255 24.1668 0 23.1481 0H23.1512Z"
                      fill="white"></path>
                  </svg> </span></a>
            </div>

            <span class="separator"></span>
          </div>
        </section>

        <section class="professionals">
          <h2 class="section-title">
            They <strong>love</strong> and <strong>trust</strong> OTONOMI
          </h2>

          <div class="columns">
            <div class="column">
              <a href="https://sgco.no/" target="_blank" rel="noopener noreferrer" class="item">
                <div class="background is-inactive"></div>
                <div class="background is-active"></div>
                <div class="message">
                  <p>
                    <strong>SG&CO</strong> engasjerte dataTrees for å utføre
                    dagslysberegninger i et
                    <strong>leilighetsprosjekt</strong> med 79 boenheter.
                    Gjennom samarbeidet fikk vi
                    <strong>kvalitetssikret</strong> at alle oppholdsrom får
                    <strong>tilfredsstillende tilgang på dagslys</strong>. Vi
                    testet også ut konsekvenser av endringer i planløsning og
                    design, OTONOMI Daylight sine analyser hjalp oss i flere
                    beslutninger. OTONOMI benytter
                    <strong>ifc-filer generert direkte fra vårt tegneprogram</strong>, dette fungerte utmerket og uten
                    ekstra tilrettelegging
                    fra vår side. De forskjellige metodene for beregning av
                    dagslys er komplekse og krever programvare og kunnskap som
                    vi som arkitekter normalt ikke har tilgang til. I enkelte
                    tilfeller passer ikke de preaksepterte metodene, da er det
                    nyttig å snakke med noen som har greie på det!
                    <strong>OTONOMI leverte som avtalt og vil bli engasjert
                      igjen!</strong>
                    ✨
                  </p>
                </div>
                <div class="author">
                  <div class="illustration">
                    <img src="../assets/images/referals/MarkusLinge.jpg" />
                  </div>
                  <div class="name">Markus Linge</div>
                  <div class="job">Daglig leder og partner, SG arkitekter</div>
                </div>
              </a>
            </div>
            <div class="column">
              <a href="https://www.bbark.no/" target="_blank" rel="noopener noreferrer" class="item">
                <div class="background is-inactive"></div>
                <div class="background is-active"></div>
                <div class="message">
                  <p>
                    OTONOMI Daylight har i et svært trangt og komplisert in-fill
                    prosjekt hjelpet oss med
                    <strong>å finne gode løsninger for dagslys</strong>. Takket
                    være et
                    <strong>smidig samarbeid gjennom flere raske iterasjoner</strong>
                    og arbeidsmøter har vi kunnet kna prosjektet i ønsket
                    retning, vi
                    <strong>kommer absolutt til å benytte oss av deres tjenester ved
                      neste anledning.</strong>👍
                  </p>
                </div>
                <div class="author">
                  <div class="illustration">
                    <img src="../assets/images/referals/BjornIvar.jpg" />
                  </div>
                  <div class="name">Bjørn Ivar Heggelund</div>
                  <div class="job">Partner og daglig leder, b+b arkitekter</div>
                </div>
              </a>
            </div>
            <div class="column">
              <a href="https://http://www.rodeo-arkitekter.no/" target="_blank" rel="noopener noreferrer" class="item">
                <div class="background is-inactive"></div>
                <div class="background is-active"></div>
                <div class="message">
                  <p>
                    OTONOMI Daylight oppleves som et
                    <strong>veldig brukervennlig, oversiktlig og ikke minst
                      fremtidsrettet</strong>
                    program som gjør dagslyssimuleringer mye mer forståelige
                    både for arkitekt og byggherre.
                  </p>
                </div>
                <div class="author">
                  <div class="illustration">
                    <img src="../assets/images/referals/CorinaRoeder.jpg" />
                  </div>
                  <div class="name">Corina Roeder</div>
                  <div class="job">
                    Senior Project Leader at Rodeo Architects, Oslo
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section class="for-who">
          <div class="content">
            <h2 class="section-title">
              {{ $t("forwho.title") }}
            </h2>
            <h3 class="section-sub-title">{{ $t("forwho.description") }}</h3>
            <div class="items">
              <div class="item">
                <Icon>architecture</Icon>
                <div class="label">{{ $t("forwho.tool") }}</div>
                <div class="description">
                  <p>{{ $t("forwho.toolDesc") }}</p>
                </div>
              </div>
              <div class="item">
                <Icon>verified</Icon>
                <div class="label">{{ $t("forwho.performance") }}</div>
                <div class="description">
                  <p>{{ $t("forwho.performanceDesc") }}</p>
                </div>
              </div>
              <div class="item">
                <Icon>wifi_protected_setup</Icon>
                <div class="label">
                  {{ $t("forwho.green") }}
                </div>
                <div class="description">
                  <p>{{ $t("forwho.greenDesc") }}</p>
                </div>
              </div>
              <div class="item">
                <Icon>bookmark_added</Icon>
                <div class="label">{{ $t("forwho.standards") }}</div>
                <div class="description">
                  <p>{{ $t("forwho.standardsDesc") }}</p>
                </div>
              </div>
              <div class="item">
                <Icon>manage_search</Icon>
                <div class="label">{{ $t("forwho.sci") }}</div>
                <div class="description">
                  <p>{{ $t("forwho.sciDesc") }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- <section class="social">
          <div class="social-items">
            <a href="/community" class="social-item"
              ><div class="social-logo">
                <svg
                  title="Slack"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="social-logo slack"
                >
                  <path
                    d="M8.37661 25.324C8.37661 27.6292 6.4935 29.5124 4.18831 29.5124C1.88311 29.5124 0 27.6292 0 25.324C0 23.0188 1.88311 21.1357 4.18831 21.1357H8.37661V25.324Z"
                    fill="black"
                    class="green"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M10.4873 25.324C10.4873 23.0188 12.3704 21.1357 14.6756 21.1357C16.9808 21.1357 18.8639 23.0188 18.8639 25.324V35.811C18.8639 38.1162 16.9808 39.9994 14.6756 39.9994C12.3704 39.9994 10.4873 38.1162 10.4873 35.811V25.324Z"
                    fill="black"
                    class="green"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M14.6756 8.37662C12.3704 8.37662 10.4873 6.4935 10.4873 4.18831C10.4873 1.88312 12.3704 0 14.6756 0C16.9808 0 18.8639 1.88312 18.8639 4.18831V8.37662H14.6756Z"
                    fill="black"
                    class="yellow"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M14.6753 10.4863C16.9805 10.4863 18.8636 12.3694 18.8636 14.6746C18.8636 16.9798 16.9805 18.8629 14.6753 18.8629H4.18831C1.88312 18.8629 0 16.9798 0 14.6746C0 12.3694 1.88312 10.4863 4.18831 10.4863H14.6753Z"
                    fill="black"
                    class="yellow"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M31.624 14.6746C31.624 12.3694 33.5071 10.4863 35.8123 10.4863C38.1175 10.4863 40.0006 12.3694 40.0006 14.6746C40.0006 16.9798 38.1175 18.8629 35.8123 18.8629H31.624V14.6746Z"
                    fill="black"
                    class="red"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M29.5133 14.6753C29.5133 16.9805 27.6302 18.8636 25.325 18.8636C23.0198 18.8636 21.1367 16.9805 21.1367 14.6753V4.18831C21.1367 1.88312 23.0198 0 25.325 0C27.6302 0 29.5133 1.88312 29.5133 4.18831V14.6753Z"
                    fill="black"
                    class="red"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M25.325 31.623C27.6302 31.623 29.5133 33.5062 29.5133 35.8113C29.5133 38.1165 27.6302 39.9996 25.325 39.9996C23.0198 39.9996 21.1367 38.1165 21.1367 35.8113V31.623H25.325Z"
                    fill="black"
                    class="blue"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                  <path
                    d="M25.325 29.5123C23.0198 29.5123 21.1367 27.6292 21.1367 25.324C21.1367 23.0188 23.0198 21.1357 25.325 21.1357H35.812C38.1172 21.1357 40.0003 23.0188 40.0003 25.324C40.0003 27.6292 38.1172 29.5123 35.812 29.5123H25.325Z"
                    fill="black"
                    class="blue"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                </svg>
              </div>
              <h3 class="social-title text-lg" data-v-5f95f452="">
                Be part of the journey
              </h3>
              <p class="social-text" data-v-5f95f452="">
                Some of the best ideas come from our users. Join our Slack
                community to shape the future of Raycast.
              </p></a
            ><a href="https://twitter.com/raycastapp" class="social-item"
              ><div class="social-logo">
                <svg
                  width="21"
                  height="28"
                  viewBox="0 0 21 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="twitter-sing"
                  data-v-5f95f452=""
                  style="opacity: 0; transform: scale(0, 0)"
                >
                  <line
                    x1="3.3638"
                    y1="0.606339"
                    x2="0.606339"
                    y2="11.6362"
                    stroke="#1DA1F2"
                    stroke-linecap="round"
                    class="line-1"
                    data-v-5f95f452=""
                  ></line>
                  <line
                    x1="20.4343"
                    y1="27.4243"
                    x2="7.42426"
                    y2="25.5657"
                    stroke="#1DA1F2"
                    stroke-linecap="round"
                    class="line-2"
                    data-v-5f95f452=""
                  ></line>
                  <line
                    x1="12.936"
                    y1="12.7042"
                    x2="7.7042"
                    y2="17.064"
                    stroke="#1DA1F2"
                    stroke-linecap="round"
                    class="line-3"
                    data-v-5f95f452=""
                  ></line></svg
                ><svg
                  title="Twitter"
                  width="50"
                  height="40"
                  viewBox="0 0 50 40"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  class="social-logo twitter"
                  data-v-5f95f452=""
                >
                  <path
                    d="M15.4528 40C34.0158 40 44.1732 24.6063 44.1732 11.2796C44.1732 10.8465 44.1732 10.4134 44.1536 9.98031C46.122 8.56299 47.8347 6.77166 49.1929 4.74409C47.3819 5.55118 45.4331 6.08268 43.3858 6.33858C45.4724 5.09842 47.0669 3.11023 47.8347 0.74803C45.8858 1.90944 43.7204 2.73622 41.4173 3.18898C39.5669 1.22047 36.9488 0 34.0551 0C28.4842 0 23.9567 4.52756 23.9567 10.0984C23.9567 10.8858 24.0551 11.6536 24.2126 12.4016C15.8268 11.9882 8.38582 7.95276 3.40551 1.85039C2.53937 3.34646 2.04724 5.07874 2.04724 6.92913C2.04724 10.4331 3.83859 13.5237 6.53543 15.3347C4.88189 15.2756 3.32677 14.8228 1.9685 14.0748C1.9685 14.1142 1.9685 14.1536 1.9685 14.2126C1.9685 19.0944 5.45276 23.189 10.0591 24.1142C9.2126 24.3504 8.32677 24.4686 7.40158 24.4686C6.75197 24.4686 6.12204 24.4094 5.51181 24.2913C6.79133 28.3071 10.5315 31.2204 14.9409 31.2992C11.4763 34.0158 7.12599 35.6299 2.40158 35.6299C1.59449 35.6299 0.787401 35.5906 0 35.4921C4.44882 38.3268 9.76378 40 15.4528 40Z"
                    fill="black"
                    class="twitter-logo"
                    data-v-5f95f452=""
                    style="fill: rgb(0, 0, 0)"
                  ></path>
                </svg>
              </div>
              <h3 class="social-title text-lg" data-v-5f95f452="">
                Stay up to date
              </h3>
              <p class="social-text" data-v-5f95f452="">
                We want to stay connected with our users on Twitter. Follow
                <strong>@datatrees</strong> to get the latest&nbsp;updates.
              </p></a
            >
          </div>
        </section> -->

        <section class="start">
          <div class="content">
            <div class="texts">
              <h2 class="section-title">{{ $t("footer.title") }}</h2>
              <h3 class="section-sub-title" v-safe-html="$t('footer.description')"></h3>
            </div>

            <Button bgColor="var(--color-orange)" txtColor="#fff" shadow="var(--shadow-dark)"
              @click="$router.push('/projects')">
              {{ $t("global.getaccess") }}
            </Button>
          </div>
        </section>
      </section>
    </main>

    <Footer></Footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { useStore } from "vuex"
import { Options, setup, Vue } from "vue-class-component"
import ThreeSun from "../components/ThreeSun.vue"
import demoRoom from "../components/DemoRoom.vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Slider from "../components/Slider.vue"
import Footer from "../components/footer.vue"

//import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
/* eslint @intlify/vue-i18n/no-v-html: 'off' */
export default defineComponent({
  name: "Home",
  components: {
    ThreeSun,
    demoRoom,
    Button,
    Icon,
    Slider,
    Footer,
  },
  setup() {
    const store = useStore()
    const login = (email: string, password: string) => {
      store.dispatch("auth/login", { email, password })
    }

    return {
      islogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      perms: computed(() => store.getters["auth/perms"]),
      login,
      store,
      refresh: () => {
        store.dispatch("auth/refresh")
      },
    }
  },
})
</script>

<style lang="scss" scoped>
@import "../css/home.scss";
</style>
