<template>
  <div class="container">
    <div class="blur" @click="close"></div>
    <!-- <transition name="fade"> -->
    <div class="modal">
      <Icon class="close" @click="close">close</Icon>
      <h1 class="header">Sign up</h1>

      <div class="details">
        <p class="light-text">
          We need some information so that you can start your first simulation.
        </p>

        <div class="form-group">
          <span class="label"><Icon>mail_outline</Icon></span>
          <span class="approve" v-if="validemail"><Icon>check</Icon></span>
          <input
            required
            class="form-field"
            type="email"
            name=""
            id="email"
            v-model="email"
            placeholder="Email"
            :class="{ error: errors.email }"
          />
        </div>
        <div v-if="errors.email" class="form-error-field">
          {{ errors.email }}
        </div>

        <div class="form-group">
          <span class="label"><Icon>person_outline</Icon></span>
          <!-- <span class="approve" v-if="validemail"><Icon>check</Icon></span> -->
          <input
            required
            class="form-field"
            type="name"
            name=""
            id="name"
            v-model="name"
            placeholder="Full name"
            :class="{ error: errors.fullname }"
          />
        </div>
        <div v-if="errors.fullname" class="form-error-field">
          {{ errors.fullname }}
        </div>

        <div class="form-group">
          <span class="label"><Icon>lock_open</Icon></span>
          <span class="approve" v-if="password.length > 7"
            ><Icon>check</Icon></span
          >
          <input
            required
            v-model="password"
            class="form-field"
            type="password"
            placeholder="Password"
            :class="{ error: errors.password }"
          />
        </div>
        <div v-if="errors.password" class="form-error-field">
          {{ errors.password }}
        </div>

        <p class="light-text">
          What's the average daylight factor in image below?
        </p>
        <img
          style="max-width: 40%; margin: 0 auto"
          :src="'data:image/jpg;base64, ' + captcha"
        />
        <div v-if="errors.captcha" class="form-error-field">
          {{ errors.captcha }}
        </div>
        <br />
        <input
          style="max-width: 30%; margin: 10px auto; text-align: center"
          type="text"
          v-model="captchavalue"
          placeholder="Value"
        />
        <Button
          bgColor="var(--color-dark-green)"
          txtColor="#ffffff"
          shadow="var(--shadow-green)"
          @click="register"
          >Sign up</Button
        >
        <p class="light-text small-text">
          We’ll use this information to contact you about your order and also
          about our products and services, which you can unsubscribe from at any
          time. To find out how and read more about our commitment to protecting
          your privacy, take a look at our Privacy Policy
        </p>
      </div>
    </div>
    <!-- </div> -->
    <!-- </transition> -->
  </div>
</template>
<script >
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import Icon from "./Icon.vue"
import Button from "./Button.vue"
import axios from "axios"
import { useGtag } from "vue-gtag-next"
import { useStore } from "vuex"

const apiurl = "https://api.otonomi.no"
// const apiurl = "http://localhost:8085"

export default defineComponent({
  components: { Icon, Button },
  props: {},

  setup(props, { emit }) {
    const route = useRoute()
    const { event } = useGtag()
    const store = useStore()
    const close = (event) => {
      emit("close")
    }
    const email = ref("")
    const name = ref("")
    const password = ref("")
    console.log(route.path)

    const errors = ref({
      email: undefined,
      password: undefined,
      fullname: undefined,
      captcha: undefined,
    })

    const validemail = computed(() => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email.value).toLowerCase())
    })

    const captcha = ref("")
    let captchatoken = ""
    const captchavalue = ref("")

    const getcaptcha = () => {
      axios({
        baseURL: "https://auth.otonomi.no",
        url: "/captcha",
        method: "get",

        responseType: "arraybuffer",
      })
        .then((res) => {
          captchatoken = res.headers.authorization
          captcha.value = Buffer.from(res.data, "binary").toString("base64")
        })
        .catch((err) => {
          console.log(err)
        })
    }

    onMounted(() => {
      getcaptcha()
      setTimeout
    })

    const register = () => {
      if (!validemail.value) {
        errors.value.email = "Invalid email"
      } else {
        errors.value.email = null
      }

      if (!password.value) {
        errors.value.password = "Password required"
      } else if (password.value.length < 8) {
        errors.value.password = "Password needs to be longer than 8 characters"
      } else {
        errors.value.password = null
      }

      if (!name.value) {
        errors.value.fullname = "Please fill in your name"
      } else {
        errors.value.fullname = null
      }

      if (!captchavalue.value) {
        errors.value.captcha = "Wrong value"
      } else {
        errors.value.captcha = null
      }

      if (validemail.value == true && password.value.length >= 8) {
        event("Account created", {
          event_category: "engagement",
          event_label: "method",
        })

        axios({
          baseURL: "https://auth.otonomi.no",
          url: "/register",
          method: "post",
          data: {
            email: email.value,
            name: name.value,
            password: password.value,
            token: captchatoken,
            value: captchavalue.value,
          },
        })
          .then((res) => {
            console.log(res)
            store.commit("setModal", 4)
          })
          .catch((err, res) => {
            console.log(err)
            console.log(err.response.data)
          })
        // emit("close")
      }
    }

    return {
      close,
      email,
      name,
      password,
      errors,
      register,
      validemail,
      captcha,
      captchavalue,
    }
  },
})
</script>
<style lang="scss" scoped>
@import "../css/home.scss";

.material-icons {
  display: flex;
}
.bottom-spacing {
  margin-bottom: 50px;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password {
  height: 0px;
  opacity: 0;
  display: none;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter-from .modal {
  opacity: 0;
}
.fade-enter-to .modal {
  opacity: 1;
}
.fade-enter-active .modal {
  transition: opacity 500ms 500ms;
}
.fade-enter-from .blur {
  opacity: 0;
  backdrop-filter: blur(0px);
}
.fade-enter-to .blur {
  opacity: 1;
  backdrop-filter: blur(6px);
}
.fade-enter-active .blur {
  transition: backdrop-filter 500ms;
}
</style>
