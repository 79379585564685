<template>
  <div class="viewer" ref="canvas"></div>
</template>

<script>
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import chroma from "chroma-js"
import { onMounted, onUnmounted, ref } from "@vue/runtime-core"

export default {
  name: "DemoRoom",
  setup() {
    let scene = {}
    let camera = {}
    let renderer = {}
    let controls = {}
    let renderRequested = false

    let dynGroup = new THREE.Group()
    dynGroup.rotateY(Math.PI * 0.95)

    let staticGroup = new THREE.Group()
    staticGroup.rotateY(Math.PI * 0.95)

    let REZ = new THREE.Group()
    REZ.rotateY(Math.PI * 0.95)

    let lights = new THREE.Group()

    onUnmounted(() => {
      dynGroup = null
      staticGroup = null
      REZ = null
      lights = null
    })

    const render = () => {
      renderRequested = false

      controls.update()

      renderer.render(scene, camera)
    }

    function onchange() {
      if (!renderRequested) {
        renderRequested = true
        requestAnimationFrame(render)
      }
    }
    const canvas = ref(null)
    const init = () => {
      scene = new THREE.Scene()
      scene.add(dynGroup, staticGroup, REZ, lights)

      let geometry = new THREE.PlaneGeometry(2000, 2000)
      geometry.rotateX(-Math.PI / 2)
      let material = new THREE.ShadowMaterial()
      material.opacity = 0.05
      let plane = new THREE.Mesh(geometry, material)
      plane.position.y = 0
      plane.receiveShadow = true
      scene.add(plane)

      const mapscale = 1

      let directionalLight = new THREE.DirectionalLight(0xede6a2, 1.9)
      directionalLight.shadow.mapSize.set(
        // canvas.value.clientWidth * 2,
        // canvas.value.clientHeight * 2
        1080 * mapscale,
        1080 * mapscale
      )
      directionalLight.shadow.camera.near = 1
      directionalLight.shadow.camera.far = 50
      directionalLight.shadow.camera.left = -25
      directionalLight.shadow.camera.top = 30
      directionalLight.shadow.camera.right = 15
      directionalLight.shadow.camera.bottom = -10
      directionalLight.shadow.radius = 4
      directionalLight.position.set(-10, 8, -10)
      directionalLight.lookAt(0, 0, 0)
      directionalLight.castShadow = true

      let directionalLight2 = new THREE.DirectionalLight(0xf0eff9, 0.4)
      directionalLight.shadow.mapSize.set(
        // canvas.value.clientWidth * 2,
        // canvas.value.clientHeight * 2
        1080 * mapscale,
        1080 * mapscale
      )
      directionalLight2.shadow.camera.near = 1
      directionalLight2.shadow.camera.far = 6
      directionalLight2.shadow.camera.left = -2
      directionalLight2.shadow.camera.top = 5
      directionalLight2.shadow.camera.right = 2
      directionalLight2.shadow.camera.bottom = -5
      directionalLight2.shadow.radius = 2
      directionalLight2.position.set(0, 4, 0)
      directionalLight2.lookAt(0, 0, 0)
      directionalLight2.castShadow = true

      lights.add(directionalLight)
      lights.add(directionalLight2)

      // const helper = new THREE.DirectionalLightHelper(directionalLight2, 2, "red")
      // lights.add(helper)

      lights.add(new THREE.AmbientLight(0xe2e0f4, 0.3))

      let pointLight = new THREE.PointLight(0xf9f4ca, 1)
      pointLight.position.set(0, 1.8, 0)
      pointLight.decay = 2
      pointLight.distance = 6
      // pointLight.castShadow = true
      lights.add(pointLight)

      let spotLight = new THREE.SpotLight("yellow", 1)
      spotLight.position.set(-0.25, 1.57, -1)
      spotLight.shadow.mapSize.width = 1080 * mapscale
      spotLight.shadow.mapSize.height = 1080 * mapscale
      spotLight.shadow.camera.near = 0.005
      spotLight.shadow.camera.far = 3
      spotLight.shadow.focus = 1
      spotLight.shadow.camera.fov = 60
      spotLight.castShadow = true
      spotLight.angle = Math.PI / 4.5
      spotLight.penumbra = 0.3
      spotLight.distance = 2
      spotLight.target.position.set(0.7, 0, -0.8)
      lights.add(spotLight.target)
      lights.add(spotLight)

      onUnmounted(() => {
        geometry = null
        material = null
        plane = null
        directionalLight = null
        directionalLight2 = null
        pointLight = null
        spotLight = null
      })

      onUnmounted(() => {
        const cleanMaterial = (material) => {
          // console.log("dispose material!")
          material.dispose()

          // dispose textures
          for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === "object" && "minFilter" in value) {
              // console.log("dispose texture!")
              value.dispose()
            }
          }
        }

        scene.traverse((object) => {
          if (!object.isMesh) return

          // console.log("dispose geometry!")
          object.geometry.dispose()

          if (object.material.isMaterial) {
            cleanMaterial(object.material)
          } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
          }
        })

        scene = null
      })

      camera = new THREE.PerspectiveCamera(
        55,
        canvas.value.clientWidth / canvas.value.clientHeightt,
        0.01,
        10
      )
      camera.position.x = 4.4
      camera.position.y = 4.5
      camera.position.z = 5.5
      onUnmounted(() => {
        camera = null
      })

      scene.add(camera)

      renderer = new THREE.WebGLRenderer({
        alpha: true,
        // color: "red",
      })

      renderer.setSize(canvas.value.clientWidth, canvas.value.clientHeight)
      //   renderer.setClearColor("white")
      renderer.setPixelRatio(2)
      renderer.outputEncoding = THREE.sRGBEncoding
      renderer.shadowMap.enabled = true
      renderer.shadowMap.type = THREE.PCFSoftShadowMap
      renderer.physicallyCorrectLights = false

      canvas.value.appendChild(renderer.domElement)
      // vue.$refs.threejs.appendChild(renderer.domElement)

      const resize = () => {
        const width = canvas.value.clientWidth
        const height = canvas.value.clientHeight
        camera.aspect = width / height
        camera.updateProjectionMatrix()
        renderer.setSize(width, height)
        if (onchange) {
          onchange()
        }
      }

      const width = canvas.value.clientWidth
      const height = canvas.value.clientHeight
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderer.setSize(width, height)

      window.addEventListener("resize", resize)

      onUnmounted(() => {
        window.removeEventListener("resize", resize)

        renderer.dispose()
        renderer = null
      })

      controls = new OrbitControls(camera, renderer.domElement)
      controls.enableDamping = true
      controls.dampingFactor = 0.1
      controls.minPolarAngle = (0.35 * Math.PI) / 2
      controls.maxPolarAngle = (0.85 * Math.PI) / 2
      controls.minAzimuthAngle = Math.PI / 20
      controls.maxAzimuthAngle = (0.85 * Math.PI) / 2
      controls.enableZoom = false
      controls.enablePan = false
      controls.target.set(0, 1, 0)

      controls.addEventListener("change", onchange)
      onUnmounted(() => {
        controls.removeEventListener("change", onchange)

        controls.dispose()
        controls = null
      })

      fetch("/demoRoom/demoRoom/DemoRoomRes.json")
        .then((res) => {
          return res.json()
        })
        .then(
          // console.log()
          function (obj) {
            // console.log(obj)
            obj = obj.map((room) => {
              return { userData: JSON.parse(room.userData) }
            })

            for (let j = 0; j < obj.length; j++) {
              const fail = obj[j].userData.UDI.splice(0, 36)
              const sup = obj[j].userData.UDI.splice(0, 36)
              const accept = obj[j].userData.UDI.splice(0, 36)
              //   const excess = obj[j].userData.UDI.splice(0, 36)

              //   console.log(obj[10].userData.UDI)
              const UDIfailScale = fail
              const UDIsupScale = fail.map((v, l) => {
                return v + sup[l]
              })
              const UDIacceptScale = UDIsupScale.map((v, l) => {
                return v + accept[l]
              })
              const UDIexcessScale = UDIsupScale.map(() => {
                return 1
              })

              // const geometry = new THREE.CircleGeometry(0.18, 32)
              const geometry = new THREE.CircleGeometry(0.15, 32)
              geometry.computeBoundingBox()
              geometry.center()
              geometry.rotateX(-Math.PI * 0.5)
              const material = new THREE.MeshBasicMaterial({
                side: THREE.DoubleSide,
              })
              const mesh = new THREE.InstancedMesh(geometry, material, 144)

              const matrix = new THREE.Matrix4()
              const offset = new THREE.Vector3()
              const orientation = new THREE.Quaternion()
              const _scale = new THREE.Vector3(1, 1, 1)
              const color = new THREE.Color()

              for (let i = 0; i < 36; i++) {
                // if (i == 22) {
                //     continue
                // }
                offset.set(-points[i][0], points[i][1], points[i][2])
                _scale.set(
                  UDIexcessScale[i],
                  UDIexcessScale[i],
                  UDIexcessScale[i]
                )
                matrix.compose(offset, orientation, _scale)
                mesh.setMatrixAt(i, matrix)
                color.set(new THREE.Color(0.91, 0.2, 0.39))
                mesh.setColorAt(i, color)

                offset.set(-points[i][0], points[i][1] + 0.005, points[i][2])
                _scale.set(
                  UDIacceptScale[i],
                  UDIacceptScale[i],
                  UDIacceptScale[i]
                )
                matrix.compose(offset, orientation, _scale)
                mesh.setMatrixAt(i + 36, matrix)
                color.set(new THREE.Color(0.95, 0.91, 0.11))
                mesh.setColorAt(i + 36, color)

                offset.set(-points[i][0], points[i][1] + 0.01, points[i][2])
                _scale.set(UDIsupScale[i], UDIsupScale[i], UDIsupScale[i])
                matrix.compose(offset, orientation, _scale)
                mesh.setMatrixAt(i + 72, matrix)
                // color.set(new THREE.Color(0.2, 0.72, 0.46))
                color.set(new THREE.Color(96 / 255, 193 / 255, 191 / 255))
                mesh.setColorAt(i + 72, color)

                offset.set(-points[i][0], points[i][1] + 0.015, points[i][2])
                _scale.set(UDIfailScale[i], UDIfailScale[i], UDIfailScale[i])
                matrix.compose(offset, orientation, _scale)
                mesh.setMatrixAt(i + 108, matrix)
                // color.set(new THREE.Color(0.28, 0.0, 0.33))
                color.set(new THREE.Color(12 / 255, 44 / 255, 132 / 255))
                mesh.setColorAt(i + 108, color)
              }
              mesh.visible = false
              REZ.add(mesh)
              //   console.log(mesh)
            }
          }
        )

      fetch("/demoRoom/demoRoom/lamp.json")
        .then((res) => {
          return res.json()
        })
        .then(function (lamp) {
          const textureLoader = new THREE.TextureLoader()
          const matcapTexture = textureLoader.load("/demoRoom/textures/4.jpg")
          for (let l = 0; l < lamp.length; l++) {
            const object = lamp[l]
            const lampGeom = new THREE.BufferGeometry()
            lampGeom.setIndex(object.faces)
            lampGeom.setAttribute(
              "position",
              new THREE.Float32BufferAttribute(object.points, 3)
            )
            lampGeom.computeVertexNormals()
            lampGeom.computeFaceNormals()
            const lampMaterial = new THREE.MeshMatcapMaterial({
              side: THREE.DoubleSide,
              matcap: matcapTexture,
              // color: "brown",
              visible: true,
              depthTest: true,
              depthWrite: true,
              transparent: false,
            })
            const lampMesh = new THREE.Mesh(lampGeom, lampMaterial)
            lampMesh.castShadow = true
            lampMesh.receiveShadow = true
            staticGroup.add(lampMesh)
          }
        })

      fetch("/demoRoom/demoRoom/DemoRoomOne.json")
        .then((res) => {
          return res.json()
        })
        .then(function (obj) {
          const materialName = []
          const materialLRLT = []
          const textureLoader = new THREE.TextureLoader()

          const matcapTexture = textureLoader.load("/demoRoom/textures/20.jpg")
          // const flooringText = textureLoader.load("/demoRoom/textures/18.jpg")
          // const slabText = textureLoader.load("/demoRoom/textures/1.jpg")
          const deskText = textureLoader.load("/demoRoom/textures/18.jpg")
          const screenText = textureLoader.load("/demoRoom/textures/11.jpg")
          // const rightWallText = textureLoader.load("/demoRoom/textures/19.jpg")
          for (let j = 0; j < obj.length; j++) {
            const object = obj[j]
            const geom = new THREE.BufferGeometry()
            geom.setIndex(object.faces)
            geom.setAttribute(
              "position",
              new THREE.Float32BufferAttribute(object.points, 3)
            )
            geom.name = object.names
            // console.log(geom.name)
            geom.computeVertexNormals()
            geom.computeFaceNormals()
            const cs = chroma
              .scale(["#7C7C7C", "#FFFFFF"])
              .domain([0, 100])
              .classes(20)(object.colors[0])
              .rgb()
            const color = new THREE.Color(cs[0] / 255, cs[1] / 255, cs[2] / 255)

            const material = new THREE.MeshMatcapMaterial({
              side: THREE.DoubleSide,
              matcap: matcapTexture,
              color: color,
              visible: true,
              depthTest: true,
              depthWrite: true,
              transparent: false,
            })
            materialName.push(object.names)
            materialLRLT.push((object.colors[0] / 255).toFixed(2))
            const mesh = new THREE.Mesh(geom, material)
            mesh.castShadow = true
            mesh.receiveShadow = true

            // const wallMaterial = 0xf0eff9
            const wallMaterial = 0xc4c2ef
            // const wallMaterial = 0x4a4ced
            // const wallMaterial = 0xb5bcc6

            if (geom.name == "flooring") {
              // mesh.material = new THREE.MeshStandardMaterial({
              //   side: THREE.DoubleSide,
              //   color: 0xffebd7,
              // })
              mesh.material = new THREE.MeshPhongMaterial({
                side: THREE.DoubleSide,
                color: 0x8f949a,
              })
            }
            if (geom.name == "slab") {
              // mesh.material = new THREE.MeshMatcapMaterial({
              //   side: THREE.DoubleSide,
              //   matcap: slabText,
              // })
              mesh.material = new THREE.MeshPhongMaterial({
                side: THREE.DoubleSide,
                color: wallMaterial,
              })
              //   const copy1 = new THREE.BufferGeometry()
              //   copy1.copy(geom)
              //   copy1.scale(100, 1, 100)
              //   copy1.translate(0, 2.75, 0)
              //   const copy1Mat = new THREE.MeshStandardMaterial({
              //     visible: true,
              //     transparent: true,
              //     opacity: 0,
              //     color: "red",
              //     side: THREE.DoubleSide,
              //   })
              //   const copy1Mesh = new THREE.Mesh(copy1, copy1Mat)
              //   copy1Mesh.receiveShadow = true
              //   copy1Mesh.castShadow = false
              //   // staticGroup.add(copy1Mesh)
            }

            if (geom.name == "desk") {
              mesh.material = new THREE.MeshStandardMaterial({
                side: THREE.DoubleSide,
                // matcap: deskText,
                color: 0xc6a887,
                depthTest: true,
                depthWrite: true,
              })
              mesh.castShadow = true
              mesh.receiveShadow = true
            }

            if (geom.name == "screen") {
              mesh.material = new THREE.MeshMatcapMaterial({
                side: THREE.DoubleSide,
                matcap: screenText,
              })
              // mesh.castShadow = true
            }

            if (geom.name == "rightWall") {
              // mesh.material = new THREE.MeshMatcapMaterial({
              //   side: THREE.DoubleSide,
              //   matcap: rightWallText,
              // })
              mesh.material = new THREE.MeshPhongMaterial({
                side: THREE.DoubleSide,
                color: wallMaterial,
              })
              const copy1 = new THREE.BufferGeometry()
              const copy2 = new THREE.BufferGeometry()
              copy1.copy(geom)
              copy2.copy(geom)
              copy1.translate(0, 0, -2.8)
              copy2.translate(0, 0, -2.8)
              copy2.rotateY(Math.PI * 0.5)
              const copy1Mat = new THREE.MeshStandardMaterial({
                visible: true,
                transparent: true,
                opacity: 0,
              })
              const copy1Mesh = new THREE.Mesh(copy1, copy1Mat)
              const copy2Mesh = new THREE.Mesh(copy2, copy1Mat)
              copy1Mesh.receiveShadow = true
              copy2Mesh.receiveShadow = true
              copy1Mesh.castShadow = true
              copy2Mesh.castShadow = true
              staticGroup.add(copy1Mesh)
              staticGroup.add(copy2Mesh)
            }

            staticGroup.add(mesh)
          }
        })

      // result fetch
      for (let i = 0; i < 47; i++) {
        fetch("/demoRoom/demoRoom/DemoRoom" + [i] + ".json")
          .then((res) => {
            return res.json()
          })
          .then(function (obj) {
            const materialName = []
            const materialLRLT = []
            const textureLoader = new THREE.TextureLoader()

            for (let j = 0; j < obj.length; j++) {
              const object = obj[j]
              const geom = new THREE.BufferGeometry()
              geom.setIndex(object.faces)
              geom.setAttribute(
                "position",
                new THREE.Float32BufferAttribute(object.points, 3)
              )
              geom.name = object.names
              geom.computeVertexNormals()
              geom.computeFaceNormals()
              const cs = chroma
                .scale(["#7C7C7C", "#FFFFFF"])
                .domain([0, 100])
                .classes(20)(object.colors[0])
                .rgb()
              const color = new THREE.Color(
                cs[0] / 255,
                cs[1] / 255,
                cs[2] / 255
              )

              const material = new THREE.MeshStandardMaterial({
                side: THREE.DoubleSide,
                // matcap: matcapTexture,
                color: color,
                visible: true,
                // wireframe: true,
                depthTest: true,
                depthWrite: true,
                transparent: false,
              })
              materialName.push(object.names)
              materialLRLT.push((object.colors[0] / 255).toFixed(2))
              const mesh = new THREE.Mesh(geom, material)
              mesh.castShadow = true
              mesh.receiveShadow = true

              const windowText = textureLoader.load("/textures/11.jpg")
              // const frameText = textureLoader.load("/demoRoom/textures/1.jpg")
              // const leftWallText = textureLoader.load("/demoRoom/textures/19.jpg")

              if (geom.name == "leftWall") {
                // mesh.material = new THREE.MeshMatcapMaterial({
                //   side: THREE.DoubleSide,
                //   matcap: leftWallText,
                // })
                mesh.material = new THREE.MeshPhongMaterial({
                  side: THREE.DoubleSide,
                  // color: 0xe2e0f4,
                  color: 0xc4c2ef,
                })
              }

              if (geom.name == "frame") {
                // mesh.material = new THREE.MeshMatcapMaterial({
                //   side: THREE.DoubleSide,
                //   matcap: frameText,
                // })
                mesh.material = new THREE.MeshPhongMaterial({
                  side: THREE.DoubleSide,
                  color: 0xc7c3ea,
                })
              }

              if (geom.name == "window") {
                mesh.material = new THREE.MeshMatcapMaterial({
                  side: THREE.DoubleSide,
                  matcap: windowText,
                  // metalness: 0.5,
                  // roughness: 0.5,
                  // emissive: "black",
                  // wireframe: false,
                })
              }
              mesh.visible = false
              dynGroup.add(mesh)
            }
          })
      }
    }

    const points = [
      [0.687681, 1.1, 0.897862],
      [0.300561, 1.1, 0.897862],
      [0.300561, 1.1, 0.510742],
      [-0.086559, 1.1, 0.897862],
      [-0.086559, 1.1, 0.510742],
      [-0.086559, 1.1, 0.123622],
      [-0.086559, 1.1, -0.263498],
      [-0.473679, 1.1, 0.897862],
      [-0.473679, 1.1, 0.510742],
      [-0.473679, 1.1, 0.123622],
      [-0.473679, 1.1, -0.263498],
      [-0.473679, 1.1, -0.650618],
      [-0.860799, 1.1, 0.897862],
      [-0.860799, 1.1, 0.510742],
      [-0.860799, 1.1, 0.123622],
      [-0.860799, 1.1, -0.263498],
      [-0.860799, 1.1, -0.650618],
      [-0.860799, 1.1, -1.037738],
      [1.074801, 1.1, 0.897862],
      [1.074801, 1.1, 0.510742],
      [1.074801, 1.1, 0.123622],
      [1.074801, 1.1, -0.263498],
      [1.074801, 1.1, -0.650618],
      [1.074801, 1.1, -1.037738],
      [0.687681, 1.1, 0.510742],
      [0.687681, 1.1, 0.123622],
      [0.687681, 1.1, -0.263498],
      [0.687681, 1.1, -0.650618],
      [0.687681, 1.1, -1.037738],
      [0.300561, 1.1, 0.123622],
      [0.300561, 1.1, -0.263498],
      [0.300561, 1.1, -0.650618],
      [0.300561, 1.1, -1.037738],
      [-0.086559, 1.1, -0.650618],
      [-0.086559, 1.1, -1.037738],
      [-0.473679, 1.1, -1.037738],
    ]

    let iter = 0
    let lastIter = undefined
    let timeoutid = undefined
    const iterate = () => {
      if (dynGroup.children.length > 0 && REZ.children.length > 0) {
        // console.log(lastIter)
        if (lastIter != undefined) {
          dynGroup.children[lastIter].visible = false
          dynGroup.children[lastIter + 1].visible = false
          REZ.children[lastIter / 3].visible = false
        }
        dynGroup.children[iter].visible = true
        dynGroup.children[iter + 1].visible = true
        // console.log(iter/3)
        REZ.children[iter / 3].visible = true

        lastIter = iter
        // console.log(iter)
        iter = iter > 45 * 3 ? 0 : iter + 3
        // return
      }
      onchange()
      timeoutid = setTimeout(iterate, 750) // stopp on unmounted
    }
    onUnmounted(() => {
      clearTimeout(timeoutid)
    })

    onMounted(() => {
      init()
      iterate()
      onchange()
    })

    return { canvas }
  },
}
</script>

<style lang="scss" scoped>
.viewer {
  width: 100%;
  height: 100%;
}
</style>