import { StaticReadUsage } from 'three'
import { createStore } from 'vuex'
//import axios from 'axios'

import { auth } from './auth'
import { toggle } from './toggle'

export default createStore({
  state: {
    modalState: 0
  },
  mutations: {
    setModal(state: any, mstate: boolean) {
      state.modalState = mstate
    }
  },
  actions: {

  },
  getters: {
  },
  modules: {
    auth,
    toggle
  }
})
