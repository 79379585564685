<template>
  <div class="NavBar" @dblclick="fullscreen()">
    <div class="logo-container">
      <a href="https://www.otonomi.no" class="main-logo is-light">
        <div class="logo">OTONOMI</div>
        <div class="text">DAYLIGHT</div>
      </a>
    </div>
    

    <!-- <div class="nav">
      
    </div> -->
    <div class="menu-container">
      <div class="hamburger" @click="showMenu = !showMenu">
        <Icon v-if="!showMenu">menu</Icon>
        <Icon v-else>close</Icon>
      </div>
      <ul
        class="menu left"
        :class="{ show: showMenu, hidden: !showNavbar }"
        v-if="!$route.meta.hideNavigation"
      >
        <li class="item">
          <router-link
            to="/viewer"
            class="demoBtn"
            @click="showMenu ? (showMenu = !showMenu) : true"
            >{{ $t("global.demo") }}</router-link
          >
        </li>
        <li class="item">
          <router-link
            to="/pricing"
            @click="showMenu ? (showMenu = !showMenu) : true"
            >{{ $t("global.price") }}</router-link
          >
        </li>

        <li class="item demoBtn">
          <router-link
            to="/faq"
            @click="showMenu ? (showMenu = !showMenu) : true"
            >{{ $t("global.faq") }}</router-link
          >
        </li>
      </ul>
      <ul class="menu right">
        <li v-if="!isLogedIn" class="item" @click="store.commit('setModal', 2)">
          {{ $t("global.login") }}
        </li>
        <li
          class="item"
          v-if="isLogedIn"
          :class="{ show: showMenu, hidden: !showNavbar }"
        >
          <Button
            bgColor="var(--color-dark-blue)"
            txtColor="#ffffff"
            shadow="var(--shadow-dark)"
            @click="$router.push('/projects')"
            >My projects</Button
          >
        </li>

        <li
          class="item separator"
          :class="{ show: showMenu, hidden: !showNavbar }"
        ></li>
        <li
          class="item flex items-center"
          :class="{ show: showMenu, hidden: !showNavbar }"
          @click="store.commit('setModal', -1)"
        >
          <Icon>language</Icon>
          <!-- <div class="current-language">EN</div> -->
        </li>
        <li class="item">
          <Button
            v-if="!isLogedIn"
            @click="store.commit('setModal', 1)"
            bgColor="var(--color-blue)"
            txtColor="#ffffff"
            shadow="var(--shadow-dark)"
            >{{ $t("global.getaccess") }}</Button
          >
        </li>
        <li
          v-if="isLogedIn"
          class="item dropdown"
          :class="{ show: showProfileMenu }"
          @click="showProfileMenu = !showProfileMenu"
        >
          <div class="user-info flex items-center">
            <span class="name">{{ username }}</span>
            <div class="initials">
              {{ username.charAt(0) }}<span class="notification"></span>
            </div>
          </div>
          <ul class="dropdown-menu">
            <!-- <li class="profile-info">
              <div class="initials">
                {{ username.charAt(0) }}{{ username.charAt(0) }}
              </div>
              <div class="email">.</div>
              <div class="company">.</div>
            </li> -->

            <li class="profile-item" @click="$router.push('/projects')">
              <Icon>grid_view</Icon>Projects
            </li>
            <!-- <li class="profile-item" @click="store.commit('setModal', 5)">
              <Icon>settings</Icon>Settings
            </li> -->
            <li class="profile-item" v-on:click="logout">
              <Icon>logout</Icon>Logout
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <div class="filler">
      <div v-if="isLogedIn" @click="logout">{{ username }}</div>
    </div> -->
  </div>
  <!-- <transition name="fade">
    <Register
      v-if="store.state.modalState == 1"
      @close="store.commit('setModal', 0)"
    />
  </transition> -->
  <transition name="fade">
    <Login
      v-if="store.state.modalState == 2 || store.state.modalState == 1"
      @close="store.commit('setModal', 0)"
    />
  </transition>
  <transition name="fade">
    <Reset
      v-if="store.state.modalState == 3"
      @close="store.commit('setModal', 0)"
    />
  </transition>
  <Success
    v-if="store.state.modalState == 4"
    @close="store.commit('setModal', 0)"
  />
  <transition name="fade">
    <UserSettings
      v-if="store.state.modalState == 5"
      @close="store.commit('setModal', 0)"
    />
  </transition>
  <transition name="fade">
    <Langselect
      v-if="store.state.modalState == -1"
      @close="store.commit('setModal', 0)"
    />
  </transition>
</template>

<script >
import {
  ref,
  defineComponent,
  watch,
  toRefs,
  computed,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
} from "vue"
import { useStore } from "vuex"
import { usePerms } from "../logic/usePerms"
import Button from "./Button.vue"
import Icon from "../components/Icon.vue"

import Login from "./Login.vue"
import Register from "./Register.vue"
import Reset from "../components/Reset.vue"
import Langselect from "../components/Langselect.vue"
import Success from "./Success.vue"
import UserSettings from "./UserSettings.vue"

export default defineComponent({
  components: {
    Button,
    Icon,

    Login,
    Register,
    Reset,
    Langselect,
    Success,
    UserSettings,
  },
  // msg!: string

  setup() {
    const app = getCurrentInstance()

    const showNavbar = ref(true)
    let lastScrollPosition = 0

    onMounted(() => {
      window.addEventListener("scroll", onScroll)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", onScroll)
    })

    const onScroll = () => {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - lastScrollPosition) < 60) {
        return
      }
      showNavbar.value = currentScrollPosition < lastScrollPosition
      lastScrollPosition = currentScrollPosition
    }

    const store = useStore()

    const { hasPerm } = usePerms()
    const login = (email, password) => {
      store.dispatch("auth/login", { email, password })
    }
    const logout = () => {
      store.dispatch("auth/logout")
    }
    const fullscreen = (elem) => {
      elem = elem || document.documentElement

      elem = document.documentElement

      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }

      animate()
    }

    return {
      isLogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      store,
      login,
      logout,
      hasPerm,
      showProfileMenu: ref(false),
      showMenu: ref(false),
      showNavbar,
      fullscreen,
    }
  },
})
</script>


<style scoped lang="scss">
@import "../css/home.scss";

.current-language {
  font-size: 0.9rem;
}
.menu {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 300;
  font-size: 1.2rem;
  opacity: 1;
  transition: all 0.4s ease-in-out;

  &.hidden {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  &.left {
    margin-right: auto;
  }

  &.right {
    .item {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .item {
    white-space: nowrap;
    position: relative;
    margin-right: 32px;
    cursor: pointer;

    // &:hover {
    //   text-decoration: underline;
    // }
    &.separator {
      border-right: 1px solid var(--color-text-lighter);
      padding: 16px 0;
    }
    &.language {
      display: flex;
    }
    &.highlight {
      font-weight: 600;
      color: var(--color-blue);
    }

    button {
      display: inline-flex;
      padding: 2px;
      font-size: 0.8rem;
    }

    a {
      display: inline;
      color: var(--color-text);
      font-weight: 700;
      &:hover {
        color: var(--color-blue);
        text-decoration: none;
      }
    }
    .router-link-active.demoBtn {
      display: none;
    }
  }
}
.NavBar {
  width: 100%;
  position: fixed;
  top: var(--edge-padding);
  padding: 0 clamp(20px, 4vw, var(--edge-padding));
  display: grid;
  grid-template-columns: 200px 8fr;
  box-sizing: border-box;
  animation: 1s ease forwards;
  animation-delay: 0.6s;
  opacity: 0;
  transform: translate3d(0, -8px, 0) scaleX(1) rotateX(0deg) rotateY(0deg)
    rotate(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  animation-name: NavBar_animation;

  @media (max-width: 800px) {
    top: 0;
    padding: calc(var(--edge-padding) * 0.5) var(--edge-padding) 0;
  }

  .menu-container {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: baseline;

    .menu {
      @media (max-width: 800px) {
        display: none;
        position: relative;
      }
      &.show {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 100px var(--edge-padding) 0;
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 90vw;
        height: 100vh;
        background: var(--color-blue);
        z-index: -1;

        .item {
          display: block;
          text-align: left;
          font-size: 1.5rem;
          margin-bottom: 25px;

          a {
            color: white;
            font-weight: 600;
            font-size: 30px;
            line-height: 31px;
            width: 100%;
            position: relative;
            display: inline-block;
          }
        }
      }
    }
    .hamburger {
      font-size: 2rem;
      display: none;
      margin-left: auto;
      cursor: pointer;
      @media (max-width: 800px) {
        display: block;
      }
      .material-icons {
        font-size: 2.5rem;
      }
    }
    &:has(.menu.show) {
      .hamburger {
        color: white;
      }
    }
  }
  .menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div {
    height: inherit;
  }
  .logo-container {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .logo {
    background: linear-gradient(90deg, #1d288f 0%, #534ac0 100%);
    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    background-clip: text;
    -webkit-background-clip: text;
    display: block;
    transition: all 0.2s ease-in-out;
    // width: 1.2rem;
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: 900;
    letter-spacing: -1.2px;
    line-height: 1.7rem;
    &:hover {
      color: rgba(0, 0, 0, 0);
    }
    @media (max-width: 600px) {
      font-size: 1.1rem;
      line-height: 1rem;
    }
  }
  @keyframes rainbow {
    0% {
      background-position: left;
    }
    50% {
      background-position: right;
    }
    100% {
      background-position: left;
    }
  }
  .text {
    position: absolute;
    left: 0;

    font-weight: 100;
    font-size: 1.2rem;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0px;
    // width: 0;
    overflow: hidden;
    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }
}
.nav {
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;
    margin: 0px 10px;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
