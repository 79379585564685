<template>
  <router-view :key="$route.fullPath + store.getters['auth/isLogedIn']" />
  <div></div>
  <NavBar></NavBar>
  <language-switcher
    v-slot="{ links }"
    active-class="router-link-exact-active"
    style="height: 0px"
  >
    <Langselect v-if="store.state.modalState == -1" :links="links">
    </Langselect>
    <!-- <router-link
      :to="link.url"
      v-for="link in links"
      :key="link.langIndex"
      :class="link.activeClass"
      exact-active-class=""
    >
      {{ link.langName }}
    </router-link> -->
  </language-switcher>
</template>

<script lang="ts" charset="utf-8">
import NavBar from "./components/Navbar.vue"
import Langselect from "./components/Langselect.vue"

import { onMounted, defineComponent } from "vue"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    NavBar,
    Langselect,
  },
  setup() {
    const store = useStore()
    const refresh = () => {
      store.dispatch("auth/refresh").catch(() => {
        return
      })
    }
    onMounted(refresh)

    return {
      refresh,
      store,
    }
  },
})
</script>


<style lang="scss">
@import "../node_modules/material-icons/iconfont/material-icons.css";
@import "css/main.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-text);
  overflow: hidden;

  &.viewer {
    height: 100vh;
  }
}

.select-menu .button em {
  --r: 45deg;
  display: block;
  position: absolute;
  right: 12px;
  top: 0;
  width: 7px;
  height: 7px;
  margin-top: 13px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.select-menu .button em:before,
.select-menu .button em:after {
  --o: 0.4;
  content: "";
  width: 7px;
  height: 7px;
  opacity: var(--o);
  display: block;
  position: relative;
  transition: opacity 0.2s ease;
  transform: rotate(var(--r)) scale(0.75);
}
.select-menu .button em:before {
  border-left: 2px solid var(--icon);
  border-top: 2px solid var(--icon);
  top: 1px;
}
.select-menu .button em:after {
  border-right: 2px solid var(--icon);
  border-bottom: 2px solid var(--icon);
  bottom: 1px;
}

// .router-language-switcher {
//   height: 30px;
//   width: 150px;
//   border: 1px #000 solid;
//   .router-link-active {
//     padding: 5px 10px;
//     z-index: 2;

//     &:not(.init) {
//       float: left;
//       width: 130px;
//       // display: none;
//       background: #ddd;
//     }
//     &:not(.init):hover,
//     &.router-link-exact-active:not(.init) {
//       background: #09f;
//     }
//     .init {
//       cursor: pointer;
//     }
//   }
// }
</style>
