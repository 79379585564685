import { computed } from 'vue'
import { useStore } from 'vuex'

export function usePerms() {

    const store = useStore()

    const hasPerm = (perm: string) => {
        const perms = computed(() => store.getters["auth/perms"])
        if (!perms.value) { return false }
        if (perms.value == {}) { return false }
        //console.log(perms.value)
        const cperm = perms.value[perm]
        if (cperm == true) { return true }
        return false
    }
    const checkToken = async () => {
        const tokenExspierd = computed(() => store.getters["auth/accessExspired"])

        if (tokenExspierd.value) {
            await store.dispatch('auth/refresh').catch(err => {
                console.log(err)
            })
            // console.log("refresh token")
        }
    }

    return {
        hasPerm,
        checkToken

    }
}