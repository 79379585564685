import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import Home from '../views/Home.vue'

// @ts-ignore
import translations from '../lang/translations'
// @ts-ignore
import localizedURLs from '../lang/localized-urls'
// @ts-ignore
import { createLangRouter } from 'vue-lang-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/projects',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/projects/:pid',
    name: 'Project',
    props: true,
    component: () => import(/* webpackChunkName: "Project" */ '../views/Project.vue')
  },
  {
    path: '/projects/:pid/:jid',
    name: 'New Project',
    props: true,
    component: () => import(/* webpackChunkName: "Project" */ '../views/Project.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/user.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/faq.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ifc',
    name: 'IfcViewer',
    component: () => import(/* webpackChunkName: "IfcViewer" */ '../views/ifcviewer.vue'),
    props: true

  },
  {
    path: '/fileupload/:type/:id',
    name: 'Fileupload',
    component: () => import(/* webpackChunkName: "Fileupload"*/ '../views/Fileupload.vue'),
    props: true

  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "Pricing" */ '../views/Pricing.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "Reset" */ '../views/Reset.vue')
  },
  {
    path: '/test',
    name: 'Stiantest',
    component: () => import(/* webpackChunkName: "test" */ '../views/stiantest.vue')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsub" */ '../views/unsub.vue'),
    props: true
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/Verify.vue'),
    props: true
  },
  {
    path: '/webinar',
    name: 'Webinar',
    component: Home,
    beforeEnter: (to, from, next) => {
      window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdenCH59i7E4okHpyrOTsTuH7Ss-Dv2XPY6WpeDbi4Vr8jtgA/viewform'
    }
  },


  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '../views/Admin.vue')
  },
  {
    path: '/viewer',
    name: 'Viewer',
    component: () => import(/* webpackChunkName: "Viewer" */ '../views/Viewer.vue'),
    props: true,
    meta: { hideNavigation: true }
  },



]



// handle accesstoken
// check if access exspierd -> refresh if needed -> check permison  



const langRouterOptions = {
  defaultLanguage: 'en',
  translations,
  localizedURLs,
}
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
}
const router = createLangRouter(langRouterOptions, routerOptions)

export default router
