import jwt_decode from "jwt-decode";
import axios from 'axios'

/* eslint-disable */

axios.defaults.baseURL = "https://auth.otonomi.no/"

function isExpired(_token: string) {
    if (_token == "") return true
    const token: any = jwt_decode(_token)
    const { exp } = token
    //check if 4 min left
    return (Date.now() >= (exp * 1000) - 240000)
}

export const auth: any = {
    namespaced: true,
    state: {
        refreshtoken: localStorage.getItem('rtoken') || "",
        perms: localStorage.getItem('rtoken') ? jwt_decode<any>(localStorage.getItem('rtoken')!).perms : [],
        token: "",
        savesession: true,
        host: process.env.VUE_APP_HOST_Auth || "https://auth.otonomi.no",
        valid: false,

    },
    mutations: {
        authsuccess(state: any, payload: any) {
            const { refreshtoken, token } = payload
            if (refreshtoken) {
                if (state.savesession) {
                    localStorage.setItem('rtoken', refreshtoken)
                }

                const rtoken: any = jwt_decode(refreshtoken)
                const { perms } = rtoken
                state.perms = perms
                state.refreshtoken = refreshtoken
            }

            state.token = token
        },
        autherror(state: any, err: any) {
            state.refreshtoken = ""
            state.token = ""
            state.perms = {}
            localStorage.removeItem('rtoken')
            axios.defaults.headers.common['Authorization'] = ''
            console.log(err)

        },
        authlogout(state: any,) {
            state.refreshtoken = ""
            state.token = ""
            state.perms = {}
            localStorage.removeItem('rtoken')
            axios.defaults.headers.common['Authorization'] = ''

        },

    },
    actions: {
        refresh({ commit, state, getters }: { commit: Function; state: any; getters: any }) {
            return new Promise((resolve, reject) => {

                const valid = !isExpired(state.refreshtoken)
                if (valid) {
                    axios({
                        baseURL: state.host,
                        url: '/refresh',
                        data: {},
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + state.refreshtoken
                        }
                    }).then(resp => {

                        const token = resp.data.token
                        commit('authsuccess', { token })
                        resolve(resp)

                    }).catch(err => {
                        commit('autherror', err)

                        reject(err)

                    })
                } else {
                    commit('autherror', { message: "no token" })
                    reject("not loged inn")
                    return
                }

            })

        },
        logout({ commit, state, getters }: { commit: Function; state: any; getters: any }) {
            return new Promise((resolve, reject) => {

                const valid = !isExpired(state.refreshtoken)
                if (valid) {
                    axios({
                        baseURL: state.host,
                        url: '/logout',
                        data: {},
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + state.refreshtoken
                        }
                    }).then(resp => {
                        commit('authlogout',)
                        resolve(resp)

                    }).catch(err => {
                        commit('autherror', err)
                        reject(err)

                    })
                } else {
                    commit('autherror', { message: "no token" })
                    reject("not loged inn")
                    return
                }
            })

        },
        login({ commit, state }: { commit: Function; state: any }, data: any) {
            const { refresh, token } = data
            commit('authsuccess', { refreshtoken: refresh, token })
        },

    },

    getters: {
        isLogedIn: (state: any) => {
            return !isExpired(state.refreshtoken)
        },
        perms: (state: any) => {

            return state.perms
        },
        host: (state: any) => {
            return state.host
        },
        accessExspired: (state: any) => {
            return isExpired(state.token)
        },

        username: (state: any) => {

            if (state.refreshtoken == "") return ""

            const token: any = jwt_decode(state.refreshtoken)
            const { username } = token

            return username
        },
        company: (state: any) => {

            if (state.refreshtoken == "") return ""

            const token: any = jwt_decode(state.refreshtoken)
            const { company } = token

            return company
        },
        token: (state: any) => { return state.token },
        refreshtoken: (state: any) => { return state.refreshtoken },
    }

}
