
import { computed, defineComponent } from "vue"
import { useStore } from "vuex"
import { Options, setup, Vue } from "vue-class-component"
import ThreeSun from "../components/ThreeSun.vue"
import demoRoom from "../components/DemoRoom.vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Slider from "../components/Slider.vue"
import Footer from "../components/footer.vue"

//import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
/* eslint @intlify/vue-i18n/no-v-html: 'off' */
export default defineComponent({
  name: "Home",
  components: {
    ThreeSun,
    demoRoom,
    Button,
    Icon,
    Slider,
    Footer,
  },
  setup() {
    const store = useStore()
    const login = (email: string, password: string) => {
      store.dispatch("auth/login", { email, password })
    }

    return {
      islogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      perms: computed(() => store.getters["auth/perms"]),
      login,
      store,
      refresh: () => {
        store.dispatch("auth/refresh")
      },
    }
  },
})
