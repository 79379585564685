// import * as THREE from 'three'
import {
    Group,
    Clock,
    Scene,
    SphereBufferGeometry,
    Mesh,
    WebGLCubeRenderTarget,
    RGBFormat,
    sRGBEncoding,
    CubeCamera,
    ShaderMaterial,
    DoubleSide,
    BackSide,
    LinearMipMapLinearFilter,
    Vector4
} from 'three'
import vertex from '../shaders/shaders/vertex.glsl'
import fragment from '../shaders/shaders/fragment.glsl'
import vertexSun from '../shaders/shadersSun/vertex.glsl'
import fragmentSun from '../shaders/shadersSun/fragment.glsl'
import vertexAround from '../shaders/shadersAround/vertex.glsl'
import fragmentAround from '../shaders/shadersAround/fragment.glsl'

class Sun {
    constructor(renderer) {
        this.group = new Group()
        this.group.name = "Sun"
        this.clock = new Clock()
        const cubeRenderscene = new Scene()
        // const renderer = new WebGLRenderer()
        // renderer.setSize(500, 500)
        // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        // renderer.setClearColor('white')
        // console.log(renderer)
        const geometryPerlin = new SphereBufferGeometry(1, 50, 50)
        //Texture
        const cubeRenderTarget = new WebGLCubeRenderTarget(256, {
            format: RGBFormat,
            generateMipmaps: true,
            minFilter: LinearMipMapLinearFilter,
            encoding: sRGBEncoding
        })
        const cubeCamera = new CubeCamera(0.1, 10, cubeRenderTarget)

        // Material
        this.materialPerlin = new ShaderMaterial({
            extensions: {
                derivatives: "#extension GL_OES_standard_derivatives: enable"
            },
            side: DoubleSide,
            uniforms: {
                uTime: { value: 0 },
                resolution: { value: new Vector4() },
            },
            vertexShader: vertex,
            fragmentShader: fragment,
        })
        //Mesh
        const meshPerlin = new Mesh(geometryPerlin, this.materialPerlin)
        cubeRenderscene.add(meshPerlin)

        // GeometrySun
        const geometrySun = new SphereBufferGeometry(1, 50, 50)
        // Material
        this.materialSun = new ShaderMaterial({
            extensions: {
                derivatives: "#extension GL_OES_standard_derivatives: enable"
            },
            side: DoubleSide,
            uniforms: {
                uTime: { value: 0 },
                uPerlin: { value: null },
                resolution: { value: new Vector4() },
            },
            vertexShader: vertexSun,
            fragmentShader: fragmentSun,
            wireframe: false
        })
        // Mesh
        const meshSun = new Mesh(geometrySun, this.materialSun)
        this.group.add(meshSun)

        // GeometryAround
        const geometryAround = new SphereBufferGeometry(1.2, 30, 30)
        // Material
        const materialAround = new ShaderMaterial({
            extensions: {
                derivatives: "#extension GL_OES_standard_derivatives: enable"
            },
            side: BackSide,
            uniforms: {
                uTime: { value: 0 },
                uPerlin: { value: null },
                resolution: { value: new Vector4() },
            },
            vertexShader: vertexAround,
            fragmentShader: fragmentAround,
            transparent: true
        })
        // Mesh
        // eslint-disable-next-line no-unused-vars  
        const meshAround = new Mesh(geometryAround, materialAround)
        this.group.add(meshAround)


        cubeCamera.update(renderer, cubeRenderscene)
        this.materialSun.uniforms.uPerlin.value = cubeRenderTarget.texture
    }

    update() {
        const elapsedTime = this.clock.getElapsedTime()

        this.materialSun.uniforms.uTime.value = elapsedTime
        this.materialPerlin.uniforms.uTime.value = elapsedTime
    }
}

export default
    Sun
