export default {
	en: {
		name: 'English',
		load: () => { return import('./en.json'); },
	},
	nb: {
		name: 'Norsk',
		load: () => { return import('./nb.json'); },
	},
	se: {
		name: 'Svenska',
		load: () => { return import('./se.json'); },
	},
};
