
// import dotenv from "dotenv"
// dotenv.config()

export const toggle: any = {
    namespaced: true,
    state: {
        f_infopanel: process.env.VUE_APP_f_infopanel != undefined ? true : false,
        dev: process.env.VUE_APP_dev != undefined ? true : false,

    },


}